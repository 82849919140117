import React from 'react';
import { Header } from '../components';
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import styles from './Contacts.module.css';

const Contacts = () => (
  <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    <Header category="Page" title="Contacts" />
    <div className={styles.contactContainer}>
    <span className={styles.contactHeader}>Email:</span>&nbsp;support@droptk.com 
    </div>
    <div className={styles.contactContainer}><span className={styles.contactHeader}>Phone:</span>&nbsp;(917)361-7727</div>
    
  </div>
);

export default Contacts;

