import React, { useEffect, useState } from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, StackingColumnSeries, Tooltip } from '@syncfusion/ej2-react-charts';

import { stackedPrimaryXAxis } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';

const Stacked = ({ width, height, rowData}) => {
  const { currentMode } = useStateContext();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  useEffect(() => {


    if(rowData.dataSource){
    let min = 0
    let max = 0
    rowData.dataSource.forEach((data) => {
      const { y } = data;
      min = Math.min(min, y);
      max = Math.max(max, y);
    })
    setMin(min);
    setMax(max * 3)
  }

  }, [rowData.dataSource])
  
  const stackedPrimaryYAxis = {
    lineStyle: { width: 0 },
    minimum: 10,
    maximum: 100,
    interval: 20,
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: '{value}',
  };
  return (
    <ChartComponent
      id="charts"
      primaryXAxis={stackedPrimaryXAxis}
      primaryYAxis={stackedPrimaryYAxis}
      width={width}
      height={height}
      chartArea={{ border: { width: 0 } }}
      tooltip={{ enable: true }}
      background={currentMode === 'Dark' ? '#33373E' : '#fff'}
      legendSettings={{ background: 'white' }}
    >
      <Inject services={[StackingColumnSeries, Category, Legend, Tooltip]} />
      <SeriesCollectionDirective>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {rowData.map((item, index) => <SeriesDirective key={index} {...item} />)}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default Stacked;
