import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { BASE_URL } from "../constant";
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
// const stripePromise = loadStripe("pk_live_51Oa80OBetN7gTyq8UogdA9RDWA4BqWfo2H5afJTTelCOT7aS3jgvQOxFMYMWQUCYtQloV5jKO8Ck20yE9HKuMyw300t0eATCBk");

const stripePromise = loadStripe(
  BASE_URL === "http://localhost:8080"
    ? "pk_test_51Oa80OBetN7gTyq8MGiWOsqx27La3uAw4fJ1i55lS24Y8gi21nFJRN7CtsYzt2cayifbIx26Q7InBK8zRTncxcmt003w9galtq"
    : "pk_live_51Oa80OBetN7gTyq8UogdA9RDWA4BqWfo2H5afJTTelCOT7aS3jgvQOxFMYMWQUCYtQloV5jKO8Ck20yE9HKuMyw300t0eATCBk"
);

export const Checkout = (props) => {
  const [clientSecret, setClientSecret] = useState("");
  const { selectedItems, setOrderInfo, handleCancel, orderInfo, type } = props;
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (selectedItems.length && !isEmpty(selectedItems[0])) {
      fetch(`${BASE_URL}/api/payment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: selectedItems, type }),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }
  }, [selectedItems]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            type={type}
            selectedItems={selectedItems}
            setOrderInfo={setOrderInfo}
            handleCancel={handleCancel}
            orderInfo={orderInfo}
            setClientSecret={setClientSecret}
          />
        </Elements>
      )}
    </div>
  );
};
