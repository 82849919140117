import {atom, selector} from 'recoil';

export const ordersAtom = atom({
    key: 'orders',
    default: []
})

export const productsAtom = atom({
    key: 'products',
    default: []
})