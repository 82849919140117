import React, { useContext, useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Resize,
} from "@syncfusion/ej2-react-grids";

import {
  gridProductProfile,
  currencyTemplate,
  contextMenuItems,
} from "../data/dummy";
import { Header } from "../components";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import styles from "./Products.module.css";
import { productsAtom } from "../atoms/atom";
import { useRecoilState } from "recoil";
import axios from "axios";
import { BASE_URL, skuShopMap, tkAppKey, tkAppSecret } from "../constant";
import { Modal, Button } from "antd";
import ManualPayment from "./ManualPayment";
import combblue from "../images/02combblue.png";
import hotairbrush from "../images/49hotairbrush.jpg";
import aajzzblack from "../images/aajzzblack.jpeg";
import aajzzpink from "../images/aajzzpink.jpeg";
import aamkf from "../images/aamkf.jpeg";
import aamnfrj from "../images/aamnfrj.jpeg";
import aaqjs from "../images/aaqjs.jpeg";
import astrolightwhite from "../images/astrolightwhite.jpg";
import autocamerablack from "../images/autocamerablack.jpeg";
import autocamerapink from "../images/autocamerapink.jpeg";
import autocamerawhite from "../images/autocamerawhite.jpeg";
import awerblue from "../images/awerblue.jpeg";
import awerorange from "../images/awerorange.jpeg";
import cardiffuserblack from "../images/cardiffuserblack.jpeg";
import chezaipin from "../images/chezaipin.jpeg";
import cow from "../images/cow.jpeg";
import hfjy from "../images/hfjy.jpeg";
import hismpurple from "../images/hismpurple.jpeg";
import jfbgold from "../images/jfbgold.jpeg";
import khairwhite from "../images/khairwhite.jpeg";
import ledykl20 from "../images/ledykl20.jpeg";
import neckmeg from "../images/neckmeg.jpeg";
import stlcupblue from "../images/stlcupblue.jpeg";
import stlcupcream from "../images/stlcupcream.jpeg";
import thermalbelt from "../images/thermalbelt.jpeg";
import u2combblack from "../images/u2combblack.jpeg";
import u2combpurple from "../images/u2combpurple.png";
import u2combred from "../images/u2combred.png";
import u2combyellow from "../images/u2combyellow.jpeg";
import mkfty5 from "../images/mkfty5.jpeg";
import trimmersilver from "../images/trimmersilver.jpeg";
import trimmerblack from "../images/trimmerblack.jpeg";
import trimmerred from "../images/trimmerred.jpeg";
import { Checkout } from "./Checkout";
import { fetchProduct } from "../atoms/selectors";
import Waitlist from "./Waitlist";
import { Tabs, message } from "antd";
import { AuthContext } from "../auth/AuthProvider";

const tkLink =
  "https://services.us.tiktokshop.com/open/authorize?service_id=7340213009804085035";

const Products = () => {
  const [productState, setProductState] = useRecoilState(productsAtom);
  const isLogin = localStorage.getItem("token");
  const handleRedirect = () => (window.location = "/login");
  const [modalVisible, setModalVisible] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(isLogin ? false : true); // State to control the visibility of the modal
  const { user } = useContext(AuthContext);
  const storedTKToken = user?.tkToken;
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      duration: 5,
      content: "Uploaded Successful, Check your Draft Products",
      style: {
        marginTop: "5vh",
        fontSize: "15px",
        fontWeight: "bold",
        color: "green",
      },
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      duration: 5,
      content:
        message ?? "Failed to upload to your store, please contact support",
      style: {
        marginTop: "5vh",
        fontSize: "15px",
        fontWeight: "bold",
        color: "red",
      },
    });
  };

  let grid;
  const dataBound = () => {
    if (grid) {
      grid.autoFitColumns(["productName", "Product"]);
    }
  };

  // const downloadProduct = async(props) => {

  //   // const urls = [brush1,brush2,brush3]
  //   const urls = []
  //   for(let field in props){
  //     if(field.includes('Image')){
  //       urls.push(props[field]);
  //     }
  //   }
  //   console.log('pppp', urls)

  //   const promises = urls.map(async (url) => {
  //     const res = await fetch(url);
  //     const blob = await res.blob();
  //     return blob
  //   })

  //   const res = await Promise.all(promises)
  //   console.log('rrr', res)
  //   const zip = new JSZip()

  //   res.forEach((blob, index) => {
  //     zip.file(`image${index}.jpg`, blob)
  //   })

  //   const zipped = await zip.generateAsync(
  //   {
  //     type: 'blob',
  //     comment: 'Optional comment for example your website URL',
  //   })
  //   // const url= URL.createObjectURL(zipped);
  //   // URL.revokeObjectURL(url)
  //       saveAs(zipped, 'brush')
  //   console.log('rrr', zipped)

  //   // zip.file('hello', props?.firstImage, { base64: true });
  //   // const zipped = await zip.generateAsync(
  //   // {
  //   //   type: 'blob',
  //   //   comment: 'Optional comment for example your website URL',
  //   // })
  //   // saveAs(zipped, 'archive file name')

  // }

  const downloadProduct = (props) => {
    window.open(props.url, "_blank");
  };

  const uploadProduct = (props) => {
    setModalVisible(true);
  };
  const hyperLinkTemplate = (props) => {
    return (
      <div>
        <a
          className={styles.downloadButton}
          href="#"
          onClick={() => (isLogin ? downloadProduct(props) : handleRedirect())}
        >
          Download
        </a>
      </div>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
    setShowMessage(false);
  };

  const handleCancel = () => {
    setCheckoutInfo({});
    setShowPayment(false);
    setIsModalOpen(false);
    setShowMessage(false);
  };

  const handlePurchase = (props) => {
    const isOutStock = Number(props.Inventory) <= 0;
    if (isOutStock) {
      setShowMessage(true);
    } else {
      setSelectedProduct(props);
    }
    setIsModalOpen(true);
  };
  const [selectedProduct, setSelectedProduct] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const urlParams = new URLSearchParams(window?.location?.search);
  const localTkToken = localStorage.getItem("tktoken");
  const localTKRefreshToken = localStorage.getItem("tkRefreshToken");

  const email = localStorage.getItem("email");

  const authCode = urlParams?.get("code");

  const getTKAccessToken = async () => {
    const tk = `${BASE_URL}/api/tiktok/accessToken`;
    const response = await axios.get(tk, {
      params: {
        auth: authCode,
      },
    });

    const shopIds = await getTKShops(response.data.access_token);
    const updates = [
      {
        email,
        tkToken: response.data.refresh_token,
        shopIds,
      },
    ];

    const userUpdateURL = `${BASE_URL}/api/users/update`;
    const userUpdate = await axios.post(userUpdateURL, {
      updates,
    });

    setAccessToken(response.data.access_token);
    if (response.data.access_token) {
      localStorage.setItem("tktoken", response.data.access_token);
    }
  };

  const getTKAccessTokenWithRefreshToken = async () => {
    const tk = `${BASE_URL}/api/tiktok/tokenWithRefresh`;
    const response = await axios.get(tk, {
      params: {
        token: localTKRefreshToken,
      },
    });
    const updates = [
      {
        email,
        tkToken: response.data.refresh_token,
      },
    ];

    const userUpdateURL = `${BASE_URL}/api/users/update`;
    const userUpdate = await axios.post(userUpdateURL, {
      updates,
    });

    setAccessToken(response.data.access_token);
    if (response.data.access_token) {
      localStorage.setItem("tktoken", response.data.access_token);
    }
  };

  const getTKOrders = async () => {
    const tk = `${BASE_URL}/api/tiktok/getOrders`;
    const response = await axios.post(tk, {
      token: localTkToken,
      ids: [],
    });
    // const foundOrder = response.data.order_list.find((order) => {
    //   if(order.order_id === orderID){
    //     return order;
    //   }
    // })
    // const trackingNumber = `${foundOrder.shipping_provider}:${foundOrder.tracking_number}`
    console.log("ggg", response.data.data);
  };

  //   const getTKInventory = async () => {

  //     const tk = `${BASE_URL}/api/tiktok/getInventory`;
  //     const response = await axios.post(tk, {
  //       token: localTkToken,
  //       ids: []
  //     })
  //     let foundProduct;

  // response.data.data.products.find((product) => {
  //     foundProduct = product.skus.find((sku) => sku.seller_sku === SKU);
  //     return foundProduct !== undefined;
  // });

  //   const inventoryNumber = foundProduct?.stock_infos?.available_stock
  //     // const trackingNumber = `${foundOrder.shipping_provider}:${foundOrder.tracking_number}`
  //     console.log('ggg', response.data)
  //   }

  const updateTKShipping = async () => {
    const tk = `${BASE_URL}/api/tiktok/updateShipment`;
    const response = await axios.post(tk, {
      token: localTkToken,
      cipher: localStorage.getItem("shopCipher"),

      // token: "TTP_OlaQcwAAAAAp2ovEFcSgqp5CHq6eQNaP4au7Pdf2oG2kQNSAkJ8aKIoTNUeONIcABG-M0pLqFvad4XQl6jK6GyizhRsV7kE6LTaNj8gAWqSFLVhybxnmSxYma4KBpzSkAgUgdVFWVbxZrBMMDtf0CTKh5ZvjEIt4TXiiuxXdcKT9DZZnByG_jQ",
      // cipher: "TTP_3ivuVgAAAAAok3Xv24dENQpKknJu7WY-",
      id: "576608991180066882",
    });

    console.log("ggg", response.data);
  };

  const getTKShops = async (token) => {
    const tk = `${BASE_URL}/api/tiktok/shop`;
    const response = await axios.get(tk, {
      params: {
        token,
      },
    });
    const shopIds = response.data.data.shops.map((shop) => {
      return shop.id;
    });
    return shopIds;
  };

  // const getTikTokSKU = async () => {

  //     const tk = `${BASE_URL}/api/tiktok/getSku`;
  //     const response = await axios.post(tk, {
  //       token: localTkToken,
  //             cipher: localStorage.getItem('shopCipher'),
  //       sellerSku: ["49-hotairbrush"]
  //     })

  //     console.log('ggg', response.data)
  //   }

  const updateTKInventory = async () => {
    const tk = `${BASE_URL}/api/tiktok/updateInventory`;
    const response = await axios.post(tk, {
      token: localTkToken,
      cipher: localStorage.getItem("shopCipher"),
      sellerSku: [{ name: "49-hotairbrush", inventory: 100 }],
    });

    console.log("ggg", response.data);
  };

  const getShopCipher = async () => {
    const tk = `${BASE_URL}/api/tiktok/shop`;
    const response = await axios.get(tk, {
      params: {
        token: localTkToken,
        ids: [],
      },
    });
    console.log("cipherrrr", response?.data);
    localStorage.setItem("shopCipher", response.data?.data?.shops?.[0]?.cipher);

    return response.data?.data?.shops?.[0]?.cipher;
  };

  async function convertFilesToFormData(files) {
    const formData = new FormData();
    files.forEach((file, index) => {
      const blob = new Blob([file], { type: file.type });
      formData.append(`file${index + 1}`, blob, file.name);
    });
    return formData;
  }

  const createTKProductAPI = async (props) => {
    const tk = `${BASE_URL}/api/tiktok/uploadImages`;
    const imageResponse = await axios.post(tk, {
      token: localTkToken,
      images: props.images,
    });

    const cipherResponse = await getShopCipher();
    const tkCategoryURL = `${BASE_URL}/api/tiktok/category`;
    const categoryResponse = await axios.post(tkCategoryURL, {
      token: localTkToken,
      productName: props.productName,
    });

    const tkWarehouseURL = `${BASE_URL}/api/tiktok/warehouses`;
    const warehouseResponse = await axios.get(tkWarehouseURL, {
      params: {
        token: localTkToken,
        cipher: cipherResponse,
      },
    });

    const category = categoryResponse?.data?.data?.leaf_category_id;
    const warehouse = warehouseResponse?.data?.data?.warehouses?.[0]?.id;
    const description = props.description;
    const images = imageResponse?.data?.results?.map((image) => {
      return { uri: image.data.uri };
    });
    const title = props.productName;
    const weight = {
      unit: "POUND",
      value: props.weight,
    };
    const skus = [
      {
        inventory: [
          {
            warehouse_id: warehouse,
            quantity: Number(props.Inventory),
          },
        ],
        seller_sku: props.SKU,
        price: {
          amount: props.Price,
          currency: "USD",
        },
      },
    ];
    const bodyParams = {
      description,
      category,
      images,
      title,
      skus,
      weight,
      cipher: cipherResponse,
    };

    const tkCreateURL = `${BASE_URL}/api/tiktok/createProduct`;
    const tkProductResponse = await axios.post(tkCreateURL, {
      token: localTkToken,
      ...bodyParams,
    });
    if (tkProductResponse?.data?.data?.product_id) {
      success();
    } else {
      error(tkProductResponse?.data?.message);
    }
  };
  const uploadToTikTok = async (props) => {
    if (!localTkToken) {
      window.open(tkLink);
    } else {
      await createTKProductAPI(props);
    }
  };
  const uploadTemplate = (props) => {
    return (
      <div>
        {/* <a className={styles.downloadButton} onClick={() => uploadProduct()}>Upload</a> */}

        <a
          className={styles.downloadButton}
          onClick={() => uploadToTikTok(props)}
        >
          Upload
        </a>
      </div>
    );
  };
  const hyperPurchaseTemplate = (props) => {
    return (
      <div>
        <a
          className={styles.downloadButton}
          onClick={() => (isLogin ? handlePurchase(props) : handleRedirect())}
        >
          Purchase
        </a>
      </div>
    );
  };

  const numberTemplate = (props, key) => {
    return (
      <div className={isLogin ? null : styles.blurry}>
        {key === "Price" ? `$${props?.[key]}` : props?.[key]}
      </div>
    );
  };

  const productsGrid = [
    {
      headerText: "Product",
      width: "300",
      template: gridProductProfile,
      textAlign: "left",
    },

    // { field: "productName", headerText: "", width: "0", textAlign: "Center" },
    { field: "SKU", headerText: "SKU", width: "160", textAlign: "Center" },
    {
      headerText: "Price",
      field: "Price",
      width: "100",
      template: (props) => numberTemplate(props, "Price"),
      textAlign: "Center",
    },

    {
      field: "Inventory",
      headerText: "Inventory",
      width: "150",
      textAlign: "Center",
      template: (props) => numberTemplate(props, "Inventory"),
    },
    //     { field: 'Weight',
    //   headerText: 'Weight(kg)',
    //   width: '150',
    //   textAlign: 'Center',
    // },
    // { field: 'dimension',
    //   headerText: 'Dimension(LxWxH)',
    //   width: '150',
    //   textAlign: 'Center',
    // },

    {
      field: "",
      headerText: "Purchase",
      width: "150",
      textAlign: "left",

      template: hyperPurchaseTemplate,
    },
    email === "lingligantz@gmail.com"
      ? {
          field: "upload",
          headerText: "Upload",
          width: "150",
          textAlign: "left",
          template: uploadTemplate,
        }
      : null,
    {
      field: "url",
      headerText: "Download",
      width: "150",
      textAlign: "left",

      template: hyperLinkTemplate,
    },
    {
      field: "warehouse",
      headerText: "Warehouse",
      width: "400",
      textAlign: "Left",
    },
  ];

  const wholesaleGrid = [
    {
      headerText: "Product",
      width: "300",
      template: gridProductProfile,
      textAlign: "left",
    },

    { field: "productName", headerText: "", width: "0", textAlign: "Center" },
    { field: "SKU", headerText: "SKU", width: "160", textAlign: "Center" },
    {
      headerText: "Price",
      field: "Price",
      width: "100",
      template: (props) => numberTemplate(props, "Price"),
      textAlign: "Center",
    },
    {
      headerText: "Minimum Quantity",
      field: "moq",
      width: "100",
      template: (props) => numberTemplate(props, "moq"),
      textAlign: "Center",
    },
    {
      field: "Inventory",
      headerText: "Inventory",
      width: "150",
      textAlign: "Center",
      template: (props) => numberTemplate(props, "Inventory"),
    },

    {
      field: "",
      headerText: "Purchase",
      width: "150",
      textAlign: "left",

      template: hyperPurchaseTemplate,
    },
    email === "lingligantz@gmail.com"
      ? {
          field: "upload",
          headerText: "Upload",
          width: "150",
          textAlign: "left",
          template: uploadTemplate,
        }
      : null,
    {
      field: "url",
      headerText: "Download",
      width: "150",
      textAlign: "left",

      template: hyperLinkTemplate,
    },
    {
      field: "warehouse",
      headerText: "Warehouse",
      width: "400",
      textAlign: "Left",
    },
  ];

  const toolbarOptions = ["Search"];

  // const editing = { allowDeleting: false, allowEditing: false };

  // const fetchShopifyProductInventory = async (products) => {
  //   const shopifyURL = `${BASE_URL}/api/shopify/product`;

  //   const shopifyProducts = products.filter((product) => skuShopMap[product["SKU"]])

  //   const promises = shopifyProducts.map(async (product) => {
  //     try {
  //       const shopifyResponse = await axios.get(shopifyURL, {
  //         params: {
  //           id: product.SKU
  //         }
  //       });
  //       return shopifyResponse.data; // Assuming you want to return the data from the response
  //     } catch (error) {
  //       console.error('Error fetching data from Shopify:', error);
  //       throw error; // Re-throw the error to be caught by Promise.all
  //     }
  //   });

  //     const results = await Promise.all(promises);

  //   const shopifyProductInventory = products.map((product) => {
  //     const foundProduct = results.find((res) => res.id === Number(product["SKU"]))
  //     if(foundProduct) {
  //       return {
  //         ...product,
  //         ["Inventory"]: foundProduct?.["inventory_quantity"]?.toString()
  //       }
  //     }else{
  //       return product
  //     }
  //   })

  //   setProductState(shopifyProductInventory)

  // }

  // const fetchProduct = async () => {

  //   const url = `${BASE_URL}/api/product`;

  //       const response = await axios.post(url);
  //       const products = response.data.product;
  //       const santitizedProduct = products.map((product) => {
  //         return {
  //           ...product,
  //           ["Price"]: product.price,
  //           ["Inventory"]: product.inventory,
  //           ["SKU"]: product.sku
  //         }
  //       })
  //       setProductState(santitizedProduct)
  //       fetchShopifyProductInventory(santitizedProduct)
  //       //inventory
  //     //   {
  //     //     "apikey":"522011657330693100",
  //     //     "timestamp":1709656178833,
  //     //     "uuid":"12345678",
  //     //     "sign":"407679F35F92C5DD73AFB91B8604E3ED",
  //     //     "apicode":"inventory.getproductinventory",
  //     //     "bizparam":"{\"sku\":\"49-hotairbrush\"}"
  //     // }
  //     //apicode=inventory.getproductinventory&apikey=522011657330693100&apisecret=gUtu2K6Lnog7lBljmXlHO3tBLWsGph6m5kq+evEiU4I=&bizparam={"sku":"49-hotairbrush"}&timestamp=1709656178833&uuid=12345678

  //     //get order tracking info

  //   //   {
  //   //     "apikey":"522011657330693100",
  //   //     "timestamp":1709660771636,
  //   //     "uuid":"12345678",
  //   //     "sign":"2BA83354E1480E95D2E7AD85C1BBBAD2",
  //   //     "apicode":"order.batchgetstatus",
  //   //     "bizparam":"{\"ExtorderNos\":[\"576594520486613948\"]}"
  //   // }
  //   // apicode=order.batchgetstatus&apikey=522011657330693100&apisecret=gUtu2K6Lnog7lBljmXlHO3tBLWsGph6m5kq+evEiU4I=&bizparam={"ExtorderNos":["576594520486613948"]}&timestamp=1709660771636&uuid=12345678

  //   //create new orders
  //   //warehousecode 6417D6AAC60B492AA53229381CD7DED3
  // //   {
  // //     "apikey":"522011657330693100",
  // //     "timestamp":1709741258574,
  // //     "uuid":"12345678",
  // //     "sign":"4C98E29B5DE758AE8AB85F85BBE13278",
  // //     "apicode":"order.addbatchv2",
  // //     "bizparam":"{\"Data\":[{\"shippingaddress\":\"test\",\"warehousecode\":\"6417D6AAC60B492AA53229381CD7DED3\",\"logisticsservice\":\"USPS-GA&PM\",\"extorderno\":\"TEST202401231422\",\"orderdate\":\"2024-03-06T07:19:01\", \"addresseename\":\"nametest\",\"address\":\"addresstest\",\"country\":\"US\",\"province\":\"NY\",\"city\":\"Holtsville\",\"postalcode\":\"00501\",\"phone\":\"13600000000\",\"orderlength\":0,\"orderwidth\":0,\"orderheight\":0,\"orderweight\":0,\"products\":[{\"sku\":\"49-hotairbrush\",\"skuqty\":1,\"skuprice\":10,\"cnname\":\"中文名称\",\"enname\":\"English\",\"sizel\":1,\"sizew\":1,\"sizeh\":1,\"weightkg\":0.1}]}]}"
  // // }

  // //apicode=order.addbatchv2&apikey=522011657330693100&apisecret=gUtu2K6Lnog7lBljmXlHO3tBLWsGph6m5kq+evEiU4I=&bizparam={"Data":[{"shippingaddress":"test","warehousecode":"6417D6AAC60B492AA53229381CD7DED3","logisticsservice":"USPS-GA&PM","extorderno":"TEST202401231422","orderdate":"2024-03-06T07:19:01", "addresseename":"nametest","address":"addresstest","country":"US","province":"NY","city":"Holtsville","postalcode":"00501","phone":"13600000000","orderlength":0,"orderwidth":0,"orderheight":0,"orderweight":0,"products":[{"sku":"49-hotairbrush","skuqty":1,"skuprice":10,"cnname":"中文名称","enname":"English","sizel":1,"sizew":1,"sizeh":1,"weightkg":0.1 }]}]}&timestamp=1709741258574&uuid=12345678
  // }

  useEffect(async () => {
    // console.log('aaaa', {red:trimmerred, silver:trimmersilver, black:trimmerblack})
    if (localTKRefreshToken && !localTkToken) {
      await getTKAccessTokenWithRefreshToken();
    }
    if (authCode && (!localTKRefreshToken || !localTkToken)) {
      await getTKAccessToken();
    }

    if (localTkToken) {
      // await getShopCipher()
      // await updateTKInventory()
      // await getTKOrders()
      // await updateTKShipping()
    }

    const products = await fetchProduct();
    setProductState(products.slice(35, products.length).reverse());
    const requestData = {
      products: products.slice(35, products.length),
    };
    await axios.post(`${BASE_URL}/api/product/updateInventory`, requestData);
  }, [authCode, storedTKToken, localTkToken]);

  const [showPayment, setShowPayment] = useState(false);

  const [checkoutInfo, setCheckoutInfo] = useState({});
  const isMobileDevice = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = [
      "iphone",
      "ipad",
      "android",
      "blackberry",
      "windows phone",
      "webos",
      "mobile",
    ];
    return mobileKeywords.some((keyword) => userAgent.includes(keyword));
  };

  const width = isMobileDevice() ? "80%" : "40%";

  const items = [
    {
      key: "retail",
      label: "Buy Piece",
      children: (
        <GridComponent
          dataSource={productState}
          // width="auto"
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          // pageSettings={{ pageCount: 5 }}
          // editSettings={editing}
          contextMenuItems={contextMenuItems}
          dataBound={dataBound}
          toolbar={toolbarOptions}
          ref={(g) => (grid = g)}
          width={isLogin ? 1000 : "100%"}
        >
          <Inject services={[Search, Page, Resize]} />

          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {productsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
        </GridComponent>
      ),
    },
    {
      key: "wholesale",
      label: "Buy Bulk",
      children: (
        <GridComponent
          dataSource={productState.filter((product) => product?.isWholesale)}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          contextMenuItems={contextMenuItems}
          toolbar={toolbarOptions}
          ref={(g) => (grid = g)}
          width={isLogin ? 1000 : "100%"}
        >
          <Inject services={[Search, Page, Resize]} />

          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {wholesaleGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
        </GridComponent>
      ),
    },
  ];

  const onChange = () => {};
  return (
    productState.length > 0 && (
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        {contextHolder}
        <Header category={isLogin ? "Page" : "DropTK"} title="Products" />
        {/* <Modal
        title="Small Modal"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
  
        <iframe title="Link to TikTok Shop" src={tkLink} style={{ width: '100%', height: '400px' }} />
      </Modal> */}
        <Modal
          maskClosable={false}
          open={showWaitlist}
          onCancel={() => setShowWaitlist(false)} // Close modal if cancelled
          footer={null} // No footer for this modal
          width={width}
          centered={true}
        >
          <Waitlist setShowWaitlist={setShowWaitlist} />{" "}
          {/* Render the Waitlist component within the modal */}
        </Modal>
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        <Modal
          title="Checkout"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          {showMessage ? (
            <div>This product does not have enough stock</div>
          ) : showPayment && checkoutInfo ? (
            <Checkout
              type={"manual"}
              selectedItems={[checkoutInfo]}
              handleCancel={handleCancel}
              orderInfo={[]}
            />
          ) : (
            <ManualPayment
              setShowPayment={setShowPayment}
              setCheckoutInfo={setCheckoutInfo}
              selectedProduct={selectedProduct}
              setShowMessage={setShowMessage}
            />
          )}
        </Modal>
      </div>
    )
  );
};
export default Products;
