import { useContext } from "react";
import { AuthContext } from "../auth/AuthProvider";
import AddPayer from "./AddPayer";
import SupplierProduct from "./SupplierProduct";
import SupplierRequest from "./SupplierRequest";
import UserInfo from "./UserInfo";

const Profile = () => {
  const { user } = useContext(AuthContext);
  const renderRroductSummary = (user) => {
    if (!user.isAdmin && !user.isBuyer) return <SupplierProduct />;
    return null;
  };
  const mainAccount = user._id === "65ad46eb15adf18a1f852b7e";
  return (
    <div className="p-14">
      <div className="space-y-8">
        <UserInfo />
        {(user.isAdmin || mainAccount) && <SupplierRequest />}
        {(user.isAdmin || mainAccount) && <AddPayer />}
        {/* {renderRroductSummary(user)} */}
      </div>
    </div>
  );
};

export default Profile;
