import React, { useRef, useState } from "react";
import styles from "./AddProduct.module.css"; // Import the CSS module
import icon from "../images/updateicon.png";
import { BASE_URL } from "../constant";
import { Modal, message } from "antd";
import axios from "axios";

function stripHtml(html) {
  // Remove HTML tags using a regular expression
  return html.replace(/<[^>]*>?/gm, "");
}

function BulkAdmin() {
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      duration: 5,
      content: "Uploaded Successful",
      style: {
        marginTop: "5vh",
        fontSize: "15px",
        fontWeight: "bold",
        color: "green",
      },
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      duration: 5,
      content: message ?? "Failed to upload, please contact support",
      style: {
        marginTop: "5vh",
        fontSize: "15px",
        fontWeight: "bold",
        color: "red",
      },
    });
  };

  const [productName, setProductName] = useState("");
  const [inventory, setInventory] = useState("");
  const [sku, setSku] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [description, setDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("");
  const [isWholesale, setIsWholesale] = useState("");
  const [moq, setMOQ] = useState("");
  const [platform, setPlatform] = useState("");
  const [dimension, setDimension] = useState("");
  const [shopifyUrl, setShopifyUrl] = useState("");
  const [token, setToken] = useState("");

  const [url, setUrl] = useState("");
  const [productImage, setProductImage] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const fileInputRef = useRef(null);
  const [image, setimage] = useState("");
  const [folderUrl, setFolderUrl] = useState("");
  const isLogin = localStorage.getItem("token");
  const email = localStorage.getItem("email");

  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDimensionChange = (e) => {
    setDimension(e.target.value);
  };

  const handleMOQChange = (e) => {
    setMOQ(e.target.value);
    setIsWholesale("true");
  };

  const handleWarehouseChange = (event) => {
    setWarehouse(event.target.value);
  };

  const handleShopifyUrlChange = (event) => {
    setShopifyUrl(event.target.value);
  };

  const handleTokenChange = (event) => {
    setToken(event.target.value);
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const response = await axios.get(`${BASE_URL}/api/shopify/allProduct`, {
      params: {
        shopifyUrl,
        token,
      },
    });
    const bulkResult = await Promise.all(
      response.data
        .filter((d) => d.status === "active")
        .map(async (item) => {
          const driveRes = await axios.post(`${BASE_URL}/api/add/driveBulk`, {
            sku: item.variants[0].id,
            images: item.images.map((image) => image.src),
          });

          const s3Res = await axios.post(`${BASE_URL}/api/add/s3Bulk`, {
            sku: item.variants[0].id,
            images: item.images.map((image) => image.src),
          });

          return {
            writer: email,
            status: "Active",
            productName: item.title,
            warehouse: warehouse || "Shopify",
            inventory: item.variants[0].inventory_quantity.toString(),
            sku: item.variants[0].id,
            isWholesale,
            moq,
            price: item.variants[0].price,
            productImage: item.image.src,
            weight: item.variants[0].weight,
            images: s3Res?.data?.uploadedFiles,
            platform: "Shopify",
            account: item.vendor,
            description: stripHtml(item.body_html),
            dimension: dimension || "10x10x10",
            category: selectedCategory,
            url: driveRes?.data?.folderUrl,
          };
        })
    );

    await axios
      .post(`${BASE_URL}/api/product/bulkCreate`, { products: bulkResult })
      .then((response) => {
        console.log("Products created successfully:", response.data);
      })
      .catch((error) => {
        console.error("Failed to create products:", error.response.data.error);
      });
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <form onSubmit={handleUpload}>
        <div className={styles.firstBox}>
          <div>
            <label htmlFor="shopifyUrl" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Shopify Url:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="shopifyUrl"
              name="shopifyUrl"
              value={shopifyUrl}
              onChange={handleShopifyUrlChange}
            />
          </div>

          <div>
            <label htmlFor="token" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Token:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="token"
              name="token"
              value={token}
              onChange={handleTokenChange}
            />
          </div>

          {/* Dimension */}
          <div>
            <label htmlFor="dimension" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Dimension LxWxH cm:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="dimension"
              name="dimension"
              value={dimension}
              onChange={handleDimensionChange}
            />
          </div>

          {/* Warehouse */}
          <div>
            <label htmlFor="warehouse" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Warehouse Address:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="warehouse"
              name="warehouse"
              value={warehouse}
              onChange={handleWarehouseChange}
            />
          </div>

          {/* Category */}
          <div>
            <label htmlFor="category" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Category:
            </label>
            <br />
            <select
              id="category"
              name="category"
              value={selectedCategory}
              onChange={handleSelectChange}
              className={styles.selectField}
            >
              <option value="Select Category">Select Category</option>
              <option value="Electronics">Electronics</option>
              <option value="Clothing">Clothing</option>
              <option value="Furniture">Furniture</option>
              <option value="Beauty">Beauty</option>
              <option value="Books">Books</option>
              <option value="Home & Kitchen">Home & Kitchen</option>
              <option value="Toys & Games">Toys & Games</option>
              <option value="Sports & Outdoors">Sports & Outdoors</option>
              <option value="Jewelry">Jewelry</option>
              <option value="Automotive">Automotive</option>
              <option value="Garden & Outdoor">Garden & Outdoor</option>
              <option value="Office Products">Office Products</option>
              <option value="Pet Supplies">Pet Supplies</option>
              <option value="Groceries">Groceries</option>
              <option value="Health & Personal Care">
                Health & Personal Care
              </option>
              <option value="Musical Instruments">Musical Instruments</option>
              <option value="Baby Products">Baby Products</option>
              <option value="Tools & Home Improvement">
                Tools & Home Improvement
              </option>
              <option value="Luggage & Travel Gear">
                Luggage & Travel Gear
              </option>
              {/* Add more categories as needed */}
            </select>
          </div>

          {/* Wholesale */}
          <div>
            <label htmlFor="isWholesale" className={styles.label}>
              Is Wholesale:
            </label>
            <br />
            <input
              type="checkbox"
              id="isWholesale"
              name="isWholesale"
              checked={isWholesale === "true"}
              onChange={(e) => {
                const value = e.target.checked ? "true" : "false";
                setIsWholesale(value);
                if (!e.target.checked) {
                  setMOQ("");
                }
              }}
            />
          </div>
          {/* MOQ */}
          <div>
            <label htmlFor="moq" className={styles.label}>
              If Wholesale, MOQ:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="number"
              id="moq"
              name="moq"
              value={moq}
              onChange={handleMOQChange}
            />
          </div>
        </div>

        <div className={styles.secondBox}>
          <div>
            <button type="submit" className={styles.uploadButton}>
              Upload
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BulkAdmin;
