import {
  BASE_URL,
  emailMap,
  shipStationMap,
  skuShopMap,
  TIME_STAMP,
  warehouseProducts,
} from "./constant";
import {
  WAREHOUSE_APIKEY,
  WAREHOUSE_APISECRET,
  WAREHOUSE_CODE,
  WAREHOUSE_URL,
} from "./constantKey";
import { v4 as uuid } from "uuid";
import { md5 } from "js-md5";
import axios from "axios";

// const generateSignatureString = (apicode, bizparam, timestamp, id) => {
//     console.log('signnnn',{apicode, bizparam, timestamp, id})
//     return `apicode=${apicode}&apikey=${WAREHOUSE_APIKEY}&apisecret=${WAREHOUSE_APISECRET}&bizparam=${bizparam}&timestamp=${timestamp}&uuid=${id}`
// }
const generateSignature = (apicode, bizparam, timestamp, id) => {
  const signatureString = `apicode=${apicode}&apikey=${WAREHOUSE_APIKEY}&apisecret=${WAREHOUSE_APISECRET}&bizparam=${bizparam}&timestamp=${timestamp}&uuid=${id}`;

  return md5(signatureString).toUpperCase();
};

export const generateWarehouseParam = (apicode, bizparam) => {
  const current = Date.now();
  const id = uuid();
  const bizParamString = JSON.stringify(bizparam);

  const param = {
    apikey: WAREHOUSE_APIKEY,
    timestamp: current,
    uuid: id,
    sign: generateSignature(apicode, bizParamString, current, id),
    apicode,
    bizparam: bizParamString,
  };
  return param;
};

export const generateUniqueOrderID = (email, orderId) => {
  return `${email}:${orderId}`;
};

export const revertOriginalOrderID = (orderId) => {
  return orderId.split(":")?.[1] ?? orderId;
};

export const getFormattedDate = () => {
  const currentDate = new Date();

  // Format the date as "MM/DD/YYYY"
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

export const orderToWarehouseConverter = (orders) => {
  return orders.map((order) => {
    const {
      id,
      amount,
      sku,
      quantity,
      address,
      city,
      name,
      phone,
      state,
      zipcode,
      productName,
    } = order;
    return {
      ["Order ID"]: id,
      ["Order Amount"]: amount,
      SKU: sku,
      Quantity: quantity,
      ["Shipping Information"]: address,
      City: city,
      ["Buyer Username"]: name,
      Phone: phone,
      State: state,
      Zipcode: zipcode,
      ["Product Name"]: productName,
    };
  });
};

const postOrderUpdate = async (orders, shopifyResponses) => {
  const url = `${BASE_URL}/api/order/update`;

  const updates = orders.map((order) => {
    const shopifyOrder = shopifyResponses.find((res) => {
      return res.note === order;
    });
    return {
      // orderID: order,
      status: "Paid",
      shopifyID: shopifyOrder?.id,
    };
  });

  const response = await axios.post(url, { updates });
};

const postShopifyOrder = async (orders, email, selectedItems) => {
  const promises = orders
    .filter(
      (order) =>
        skuShopMap[order.sku] ||
        (order?.platform === "Shopify" && order?.account)
    )
    .map(async (item) => {
      const shopifyURL = `${BASE_URL}/api/shopify/order/create`;
      const order = {
        id: item.sku,
        quantity: item.quantity,
        email,
        name: item.name,
        address: item.address,
        phone: item.phone,
        city: item.city,
        province: item.state,
        zipcode: item.zipcode,
        note: generateUniqueOrderID(email, item.id),
      };
      try {
        const shopifyRes = await axios.post(shopifyURL, order);
        return shopifyRes.data; // Assuming you want to return the data from the response
      } catch (error) {
        console.error("Error posting order to Shopify:", error);
        throw error; // Re-throw the error to be caught by Promise.all
      }
    });

  try {
    const results = await Promise.all(promises);
    const orders = selectedItems.map((item) =>
      generateUniqueOrderID(email, item.id)
    );
    postOrderUpdate(orders, results);
    return results;
  } catch (error) {
    console.error("Error posting orders to Shopify:", error);
    throw error;
  }
};

const postShipStationOrderUpdate = async (orders, shipStationResponses) => {
  const url = `${BASE_URL}/api/order/update`;

  const updates = orders.map((order) => {
    const shipStationOrder = shipStationResponses.find((res) => {
      return res.orderNumber === order;
    });
    return {
      // orderID: order,
      status: "Paid",
      shipStationID: shipStationOrder?.orderNumber,
    };
  });

  const response = await axios.post(url, { updates });
};

const postShipStationOrder = async (orders, email, selectedItems) => {
  const promises = orders
    .filter((order) => shipStationMap[order.sku])
    .map(async (item) => {
      const shipStationURL = `${BASE_URL}/api/shipstation/order/create`;
      const orderDate = new Date().toISOString();
      const order = {
        orderNumber: generateUniqueOrderID(email, item.id),
        orderDate,
        orderStatus: "awaiting_shipment",
        items: [
          {
            sku: item.sku,
            name: item.productName,
            quantity: item.quantity,
            unitPrice: item.amount,
          },
        ],
        internalNotes: "DropTK",
        shipTo: {
          name: item.name,
          street1: item.address,
          city: item.city,
          state: item.state,
          postalCode: item.zipcode,
          country: "US",
          phone: item.phone,
        },
        billTo: {
          name: item.name,
          street1: item.address,
          city: item.city,
          state: item.state,
          postalCode: item.zipcode,
          country: "US",
          phone: item.phone,
        },
      };
      try {
        const shipstationRes = await axios.post(shipStationURL, order);
        return shipstationRes.data; // Assuming you want to return the data from the response
      } catch (error) {
        console.error("Error posting order to Shipstation:", error);
        throw error; // Re-throw the error to be caught by Promise.all
      }
    });

  try {
    const results = await Promise.all(promises);
    const orders = selectedItems.map((item) => item.id);
    postShipStationOrderUpdate(orders, results);
    return results;
  } catch (error) {
    console.error("Error posting orders to Shipstation:", error);
    throw error;
  }
};

const generateOrder = async (orders, email) => {
  const none = orders.every(
    (order) => !warehouseProducts.includes(order["SKU"])
  );
  if (none) {
    return;
  }
  const currentDate = new Date();
  const warehouseOrderDate = currentDate.toISOString().slice(0, 19);
  const dataArr = orders
    .filter((order) => warehouseProducts.includes(order["SKU"]?.trim()))
    .map((order) => {
      return {
        shippingaddress: "",
        warehousecode: WAREHOUSE_CODE,
        logisticsservice: "USPS-GA&PM",
        extorderno: generateUniqueOrderID(email, order["Order ID"]?.toString()),
        orderdate: warehouseOrderDate,
        addresseename: order["Buyer Username"],

        address: order["Shipping Information"],
        country: "United States",
        province: order["State"],
        city: order["City"],
        postalcode: order["Zipcode"]?.toString(),
        phone: order["Phone"],
        country: "US",
        // province:"NY",city:"New York",
        // postalcode:"32132",
        // phone:"121321",

        orderlength: 0,
        orderwidth: 0,
        orderheight: 0,
        orderweight: 0,
        products: [
          {
            sku: order["SKU"]?.trim(),
            skuqty: order["Quantity"],
            skuprice: order["Order Amount"],
            cnname: "中文名称",
            enname: order["Product Name"],
            sizel: 1,
            sizew: 1,
            sizeh: 1,
            weightkg: 0.1,
          },
        ],
      };
    });
  const apiCode = "order.addbatchv2";

  const bizparam = {
    Data: dataArr,
  };
  const apiParam = generateWarehouseParam(apiCode, bizparam);
  const result = await axios.post(WAREHOUSE_URL, apiParam);
};

const updateProduct = async (productToUpdate) => {
  const url = `${BASE_URL}/api/product/update`;

  await axios.post(url, { product: productToUpdate });
};

export const afterPayment = async (
  selectedItems,
  type,
  orderInfo,
  setProductState,
  setOrderInfo,
  email,
  productState
) => {
  const originalOrderID = selectedItems.map((item) => item.id);
  const paidOrders = orderInfo?.filter((order) =>
    originalOrderID.includes(order["Order ID"])
  );
  if (type === "manual") {
    const item = selectedItems[0];
    const uniqueID = generateUniqueOrderID(email, item.id);
    let shopifyRes;
    if (
      skuShopMap[item.sku] ||
      (item?.platform === "Shopify" && item?.account)
    ) {
      const shopifyURL = `${BASE_URL}/api/shopify/order/create`;
      const order = {
        id: item.sku,
        quantity: item.quantity,
        email,
        name: item.name,
        address: item.address,
        phone: item.phone,
        city: item.city,
        province: item.state,
        zipcode: item.zipcode,
        note: uniqueID,
      };

      shopifyRes = await axios.post(shopifyURL, order);
    }
    let shipStationRes;

    if (shipStationMap[item.sku]) {
      const shipStationURL = `${BASE_URL}/api/shipstation/order/create`;
      const orderDate = new Date().toISOString();
      const order = {
        orderNumber: item.id,
        orderDate,
        orderStatus: "awaiting_shipment",
        items: [
          {
            sku: item.sku,
            name: item.productName,
            quantity: item.quantity,
            unitPrice: item.amount,
          },
        ],
        internalNotes: "DropTK",
        shipTo: {
          name: item.name,
          street1: item.address,
          city: item.city,
          state: item.state,
          postalCode: item.zipcode,
          country: "US",
          phone: item.phone,
        },
        billTo: {
          name: item.name,
          street1: item.address,
          city: item.city,
          state: item.state,
          postalCode: item.zipcode,
          country: "US",
          phone: item.phone,
        },
      };
      shipStationRes = await axios.post(shipStationURL, order);
    }
    if (
      emailMap.includes(item.sku) ||
      BASE_URL === "https://droptok.onrender.com"
    ) {
      const emailURL = `${BASE_URL}/api/email/send`;
      const text = `
      Order Number: ${email}:${item.id}
  SKU: ${item.sku}
  Quantity: ${item.quantity}
  Name: ${item.name}
  Address: ${item.address}
  Phone: ${item.phone}
  City: ${item.city}
  State: ${item.state}
  Zipcode: ${item.zipcode}
`;
      const email = {
        subject: "DropTK Order",
        text,
      };
      await axios.post(emailURL, email);
    }
    const url = `${BASE_URL}/api/order/upload`;

    const orderToUpload = selectedItems.map((item) => {
      return {
        writer: email,
        orderID: uniqueID,
        status: "Paid",
        productName: item.productName,
        buyer: item.name,
        address: item.address,
        amount: item.amount,
        sku: item.sku,
        quantity: item.quantity,
        trackingNumber: null,
        date: getFormattedDate(),
        phone: item.phone,
        state: item.state,
        city: item.city,
        zipcode: item.zipcode,
        shopifyID: shopifyRes?.data?.id,
        shipStationID: shipStationRes?.data?.orderNumber,
      };
    });

    await axios.post(url, orderToUpload);
  } else {
    setOrderInfo &&
      setOrderInfo((prevOrders) => {
        const result = prevOrders.map((order) => {
          const foundItem = selectedItems.find((item) => {
            return item.id === order["Order ID"];
          });
          if (foundItem) {
            return {
              ...order,
              ["Order Status"]: "Paid",
            };
          } else {
            return order;
          }
        });

        return result;
      });

    postShopifyOrder(selectedItems, email, selectedItems);
    postShipStationOrder(selectedItems, email, selectedItems);
    selectedItems.forEach(async (item) => {
      if (
        emailMap.includes(item.sku) ||
        BASE_URL === "https://droptok.onrender.com"
      ) {
        const emailURL = `${BASE_URL}/api/email/send`;
        const text = `
  Order Number: ${email}:${item.id}
  SKU: ${item.sku}
  Quantity: ${item.quantity}
  Name: ${item.name}
  Address: ${item.address}
  Phone: ${item.phone}
  City: ${item.city}
  State: ${item.state}
  Zipcode: ${item.zipcode}
`;
        const email = {
          subject: "DropTK Order",
          text,
        };
        await axios.post(emailURL, email);
      }
    });
  }

  generateOrder(
    type === "manual"
      ? orderToWarehouseConverter(selectedItems, email)
      : paidOrders
  );

  const paidSKU = selectedItems.reduce((res, item) => {
    if (res[item.sku]) {
      res[item.sku] = res[item.sku] + Number(item.quantity);
    } else {
      res[item.sku] = Number(item.quantity);
    }
    return res;
  }, {});

  const originalSkuData = productState.reduce((res, item) => {
    res[item["SKU"]] = item;
    return res;
  }, {});
  const updatedProductInventory = productState.map((product) => {
    if (paidSKU[product.SKU]) {
      return {
        ...product,
        ["Inventory"]: product.Inventory - paidSKU[product.SKU],
      };
    } else {
      return product;
    }
  });
  const dbProductUpdate = [];
  for (let sku in paidSKU) {
    dbProductUpdate.push({ sku, inventory: paidSKU[sku] });
  }

  updateProduct(dbProductUpdate);
  setProductState(updatedProductInventory);
};
