import React, { useRef, useState } from "react";
import styles from "./AddProduct.module.css"; // Import the CSS module
import icon from "../images/updateicon.png";
import { BASE_URL } from "../constant";
import { Modal, message } from "antd";

function Add() {
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      duration: 5,
      content: "Uploaded Successful",
      style: {
        marginTop: "5vh",
        fontSize: "15px",
        fontWeight: "bold",
        color: "green",
      },
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      duration: 5,
      content: message ?? "Failed to upload, please contact support",
      style: {
        marginTop: "5vh",
        fontSize: "15px",
        fontWeight: "bold",
        color: "red",
      },
    });
  };

  const [productName, setProductName] = useState("");
  const [inventory, setInventory] = useState("");
  const [sku, setSku] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [description, setDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImageSrc, setCurrentImageSrc] = useState("");
  const [weight, setWeight] = useState("");
  const [price, setPrice] = useState("");
  const [isWholesale, setIsWholesale] = useState("");
  const [moq, setMOQ] = useState("");
  const [platform, setPlatform] = useState("");
  const [dimension, setDimension] = useState("");

  const [url, setUrl] = useState("");
  const [productImage, setProductImage] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Select Category");
  const fileInputRef = useRef(null);
  const [image, setimage] = useState("");
  const [folderUrl, setFolderUrl] = useState("");
  const isLogin = localStorage.getItem("token");
  const email = localStorage.getItem("email");

  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleInputChange = (e) => {
    setProductName(e.target.value);
  };

  const handleDimensionChange = (e) => {
    setDimension(e.target.value);
  };

  const handlePlatformChange = (e) => {
    setPlatform(e.target.value);
  };

  const handleWholesaleChange = (e) => {
    setIsWholesale(e.target.value);
  };

  const handleMOQChange = (e) => {
    setMOQ(e.target.value);
    setIsWholesale("true");
  };

  const handleInventoryChange = (event) => {
    setInventory(event.target.value);
  };

  const handleWarehouseChange = (event) => {
    setWarehouse(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleSkuChange = (event) => {
    setSku(event.target.value);
  };

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const previews = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        previews.push(reader.result);
        if (previews.length === files.length) {
          setImagePreviews(previews);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleClearPreview = () => {
    setImagePreviews([]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const files = fileInputRef.current.files;

    if (files.length > 0) {
      const formData = new FormData();

      // Upload image files
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      try {
        // Upload images
        formData.append("sku", sku);
        const imageResponse = await fetch(`${BASE_URL}/api/add/upload-drive`, {
          method: "POST",
          body: formData,
        });

        const imageURL = await fetch(`${BASE_URL}/api/add/upload-s3`, {
          method: "POST",
          body: formData,
        });

        if (!imageURL.ok) {
          throw new Error("Failed to upload files");
        }

        const responseData = await imageURL.json();

        const pImage = responseData.imageURL;
        console.log(pImage);

        if (!imageResponse.ok) {
          throw new Error("Image upload failed");
        }

        // Parse JSON response for uploaded images
        const imageUploadData = await imageResponse.json();
        console.log("Uploaded files", imageUploadData.files);

        const driveUrl = imageUploadData.folderUrl;
        console.log("Drive URL:", driveUrl);

        // Update state with the folder URL
        setFolderUrl(driveUrl);

        const productData = {
          productName,
          inventory,
          sku,
          warehouse,
          writer: email,
          status: "Active",
          weight,
          price,
          dimension,
          url: driveUrl,
          productImage: pImage,
          category: selectedCategory,
          images: pImage,
          platform,
          isWholesale,
          moq,
          description,
        };

        console.log(productData);

        const productResponse = await fetch(`${BASE_URL}/api/product/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...productData,
            url: driveUrl,
            productImage: pImage,
          }),
        });

        if (!productResponse.ok) {
          throw new Error("Product creation failed");
        }

        success();
      } catch (error) {
        error();
        console.error("Error:", error);
      }
    }
  };

  const handleDeleteImage = (index) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
  };

  const enlargeImage = (imageSrc) => {
    setCurrentImageSrc(imageSrc);
    setIsModalVisible(true);
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      {/* <h2 className={styles.title}>Product Media Upload</h2> */}
      <form onSubmit={handleUpload} encType="multipart/form-data">
        <div className={styles.firstBox}>
          {/* Product Name */}
          <div>
            <label htmlFor="productName" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Product Name:
            </label>
            <br />
            <h5
              style={{ marginBottom: "11px", marginTop: "11px", color: "grey" }}
            >
              Recommended length: 25 characters or more. Category will be
              identified by using the product.
            </h5>
            <input
              className={styles.inputField}
              type="text"
              id="productName"
              name="productName"
              value={productName}
              onChange={handleInputChange}
              placeholder="[Brand] + [Product Name]"
              required
            />
          </div>

          <div>
            <label htmlFor="description" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Description:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="description"
              name="description"
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>

          {/* SKU */}
          <div>
            <label htmlFor="sku" className={styles.label}>
              <span style={{ color: "red" }}>*</span>SKU:
            </label>
            <br />
            <h5
              style={{ marginBottom: "11px", marginTop: "11px", color: "grey" }}
            >
              Suggested length: 6-20 characters
            </h5>
            <input
              className={styles.inputField}
              type="text"
              id="sku"
              name="sku"
              value={sku}
              onChange={handleSkuChange}
              required
            />
          </div>

          {/* Weight */}
          <div>
            <label htmlFor="weight" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Weight in Pound:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="weight"
              name="weight"
              value={weight}
              onChange={handleWeightChange}
            />
          </div>

          {/* Inventory */}
          <div>
            <label htmlFor="inventory" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Inventory:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="inventory"
              name="inventory"
              value={inventory}
              onChange={handleInventoryChange}
            />
          </div>

          {/* Price */}
          <div>
            <label htmlFor="price" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Price:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="number"
              id="price"
              name="price"
              value={price}
              onChange={handlePriceChange}
            />
          </div>

          {/* Dimension */}
          <div>
            <label htmlFor="dimension" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Dimension LxWxH cm:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="dimension"
              name="dimension"
              value={dimension}
              onChange={handleDimensionChange}
            />
          </div>

          {/* Warehouse */}
          <div>
            <label htmlFor="warehouse" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Warehouse Address:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="text"
              id="warehouse"
              name="warehouse"
              value={warehouse}
              onChange={handleWarehouseChange}
            />
          </div>

          {/* Platform */}
          <div>
            <label htmlFor="platform" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Communication Method:
            </label>
            <br />
            <select
              id="platform"
              name="platform"
              value={platform}
              onChange={handlePlatformChange}
              className={styles.selectField}
            >
              <option value="Select Platform">
                Select Communication Method
              </option>
              <option value="Email">Automated Email</option>
              <option value="Shopify">Shopify API</option>
              <option value="ShipStation">ShipStation API</option>
            </select>
          </div>

          {/* Category */}
          <div>
            <label htmlFor="category" className={styles.label}>
              <span style={{ color: "red" }}>*</span>Category:
            </label>
            <br />
            <select
              id="category"
              name="category"
              value={selectedCategory}
              onChange={handleSelectChange}
              className={styles.selectField}
            >
              <option value="Select Category">Select Category</option>
              <option value="Electronics">Electronics</option>
              <option value="Clothing">Clothing</option>
              <option value="Furniture">Furniture</option>
              <option value="Beauty">Beauty</option>
              <option value="Books">Books</option>
              <option value="Home & Kitchen">Home & Kitchen</option>
              <option value="Toys & Games">Toys & Games</option>
              <option value="Sports & Outdoors">Sports & Outdoors</option>
              <option value="Jewelry">Jewelry</option>
              <option value="Automotive">Automotive</option>
              <option value="Garden & Outdoor">Garden & Outdoor</option>
              <option value="Office Products">Office Products</option>
              <option value="Pet Supplies">Pet Supplies</option>
              <option value="Groceries">Groceries</option>
              <option value="Health & Personal Care">
                Health & Personal Care
              </option>
              <option value="Musical Instruments">Musical Instruments</option>
              <option value="Baby Products">Baby Products</option>
              <option value="Tools & Home Improvement">
                Tools & Home Improvement
              </option>
              <option value="Luggage & Travel Gear">
                Luggage & Travel Gear
              </option>
              {/* Add more categories as needed */}
            </select>
          </div>

          {/* Wholesale */}
          <div>
            <label htmlFor="isWholesale" className={styles.label}>
              Is Wholesale:
            </label>
            <br />
            <input
              type="checkbox"
              id="isWholesale"
              name="isWholesale"
              checked={isWholesale === "true"}
              onChange={(e) => {
                const value = e.target.checked ? "true" : "false";
                setIsWholesale(value);
                if (!e.target.checked) {
                  setMOQ("");
                }
              }}
            />
          </div>
          {/* MOQ */}
          <div>
            <label htmlFor="moq" className={styles.label}>
              If Wholesale, MOQ:
            </label>
            <br />
            <input
              className={styles.inputField}
              type="number"
              id="moq"
              name="moq"
              value={moq}
              onChange={handleMOQChange}
            />
          </div>
        </div>

        <div className={styles.secondBox}>
          <div>
            <input
              type="file"
              id="fileInput"
              name="files"
              onChange={handleImageChange}
              ref={fileInputRef}
              multiple
              className={styles.fileInput}
            />
            <label
              htmlFor="fileInput"
              className={styles.fileInputLabel}
            ></label>
            <br />
          </div>
          <div className={styles.imagePreview}>
            {imagePreviews.map((preview, index) => (
              <img key={index} src={preview} alt="Preview" />
            ))}
          </div>
          <div>
            <button
              type="button"
              className={styles.clearButton}
              onClick={handleClearPreview}
            >
              Clear
            </button>
            <button type="submit" className={styles.uploadButton}>
              Upload
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Add;
