import { BASE_URL, sheetMap, skuShopMap, warehouseProducts } from "../constant";
import axios from "axios";
import {
  generateUniqueOrderID,
  generateWarehouseParam,
  revertOriginalOrderID,
} from "../util";
import { WAREHOUSE_URL } from "../constantKey";

const fetchSheetInventory = async (sheetProducts) => {
  const sheetURL = `${BASE_URL}/api/sheet/product`;

  const promises = sheetProducts.map(async (product) => {
    try {
      const sheetResponse = await axios.get(sheetURL, {
        params: {
          sku: product.SKU,
        },
      });
      return sheetResponse.data; // Assuming you want to return the data from the response
    } catch (error) {
      console.error("Error fetching data from Sheet:", error);
      throw error; // Re-throw the error to be caught by Promise.all
    }
  });

  const results = await Promise.all(promises);

  const sheetProductInventory = sheetProducts?.map((product) => {
    const foundProduct = results?.find(
      (res) => res?.["SKU"]?.trim() === product?.["SKU"]?.trim()
    );
    if (foundProduct) {
      return {
        ...product,
        ["Inventory"]: foundProduct?.["Inventory"]?.toString(),
      };
    } else {
      return product;
    }
  });

  return sheetProductInventory;
};
const fetchShopifyProductInventory = async (shopifyProducts) => {
  const shopifyURL = `${BASE_URL}/api/shopify/product`;

  const promises = shopifyProducts.map(async (product) => {
    try {
      const shopifyResponse = await axios.get(shopifyURL, {
        params: {
          id: product?.SKU,
        },
      });
      return shopifyResponse?.data; // Assuming you want to return the data from the response
    } catch (error) {
      console.error("Error fetching data from Shopify:", error);
      throw error; // Re-throw the error to be caught by Promise.all
    }
  });

  const results = await Promise.all(promises);

  const shopifyProductInventory = shopifyProducts.map((product) => {
    const foundProduct = results?.find(
      (res) => res?.id === Number(product?.["SKU"])
    );
    if (foundProduct) {
      return {
        ...product,
        ["Inventory"]: foundProduct?.["inventory_quantity"]?.toString(),
      };
    } else {
      return product;
    }
  });

  return shopifyProductInventory;
};

export const fetchProduct = async () => {
  const url = `${BASE_URL}/api/product`;

  const response = await axios.post(url);
  const products = response.data.product;
  const santitizedProduct = products.map((product) => {
    return {
      ...product,
      ["Price"]: product.price,
      ["Inventory"]: product.inventory,
      ["SKU"]: product.sku,
    };
  });
  const skuIndexMap = new Map();
  santitizedProduct.forEach((product, index) => {
    skuIndexMap.set(product.SKU, index);
  });

  const sheetProducts = santitizedProduct.filter(
    (product) => sheetMap[product["SKU"]]
  );
  const shopifyProducts = santitizedProduct.filter(
    (product) => skuShopMap[product["SKU"]]
    // ||
    // (product?.platform === "Shopify" && product?.account)
  );

  const sheet = await fetchSheetInventory(sheetProducts);

  const shopify = await fetchShopifyProductInventory(shopifyProducts);

  let combinedProducts = [...sheet, ...shopify];

  // Add products that do not belong to either the Google Sheet or Shopify
  const otherProducts = santitizedProduct.filter(
    (product) =>
      !sheetMap[product["SKU"]] &&
      (!skuShopMap[product["SKU"]] ||
        !(product?.platform === "Shopify" && product?.account))
  );
  combinedProducts = [...combinedProducts, ...otherProducts];

  // Sort the combinedProducts array based on the original order in santitizedProduct
  combinedProducts.sort((a, b) => {
    const indexA = skuIndexMap.get(a.SKU);
    const indexB = skuIndexMap.get(b.SKU);
    return indexA - indexB;
  });

  // Return the combined products
  return combinedProducts;
};

const getSheetOrderInfo = async (orders) => {
  if (orders.length) {
    const sheetURL = `${BASE_URL}/api/sheet`;

    const sheetOrders = orders.map((order) => order["Order ID"]);
    const sheetResponse = await axios.get(sheetURL, {
      params: {
        orderNumbers: sheetOrders,
        sku: orders[0]?.SKU,
      },
    });

    const sheetOrderTracking = orders.map((order) => {
      const foundOrder = sheetResponse?.data?.find(
        (res) => res.orderNumber.trim() === order["Order ID"]
      );
      if (foundOrder) {
        return {
          ...order,
          ["Tracking Number"]: foundOrder.trackingNumber.trim(),
        };
      } else {
        return order;
      }
    });

    return sheetOrderTracking;
  }
  return [];
};

const getShopifyOrderInfo = async (orders) => {
  const shopifyURL = `${BASE_URL}/api/shopify/order`;

  const shopifyOrders = orders.filter((order) => order.shopifyID);

  const promises = shopifyOrders.map(async (order) => {
    try {
      const shopifyResponse = await axios.get(shopifyURL, {
        params: {
          id: order.shopifyID,
          sku: order.SKU,
        },
      });
      return shopifyResponse.data; // Assuming you want to return the data from the response
    } catch (error) {
      console.error("Error fetching data from Shopify:", error);
      throw error; // Re-throw the error to be caught by Promise.all
    }
  });

  const results = await Promise.all(promises);

  const shopifyOrderTracking = orders.map((order) => {
    const foundOrder = results.find(
      (res) => res.id?.toString() === order.shopifyID
    );
    if (foundOrder) {
      return {
        ...order,
        ["Tracking Number"]:
          foundOrder?.fulfillments?.[0]?.["tracking_company"] &&
          foundOrder?.fulfillments?.[0]?.["tracking_number"]
            ? `${foundOrder?.fulfillments?.[0]?.["tracking_company"]}:${foundOrder?.fulfillments?.[0]?.["tracking_number"]}`
            : null,
      };
    } else {
      return order;
    }
  });

  return shopifyOrderTracking;
};

const getShipStationOrderInfo = async (orders) => {
  const shipStationURL = `${BASE_URL}/api/shipstation/order`;

  const shipStationOrders = orders.filter((order) => order.shipStationID);

  const promises = shipStationOrders.map(async (order) => {
    try {
      const shipStationResponse = await axios.get(shipStationURL, {
        params: {
          id: order.shipStationID,
          sku: order.SKU,
        },
      });
      return shipStationResponse.data; // Assuming you want to return the data from the response
    } catch (error) {
      console.error("Error fetching data from ShipStation:", error);
      throw error; // Re-throw the error to be caught by Promise.all
    }
  });

  const results = await Promise.all(promises);

  const shipStationOrderTracking = orders.map((order) => {
    const foundOrder = results.find((res) =>
      res?.fulfillments?.find(
        (shipment) => shipment.orderNumber === order.shipStationID
      )
    );
    if (foundOrder?.fulfillments?.length) {
      return {
        ...order,
        ["Tracking Number"]:
          foundOrder?.fulfillments?.[0]?.["trackingNumber"] &&
          foundOrder?.fulfillments?.[0]?.["carrierCode"]
            ? `${foundOrder?.fulfillments?.[0]?.["carrierCode"]}:${foundOrder?.fulfillments?.[0]?.["trackingNumber"]}`
            : null,
      };
    } else {
      return order;
    }
  });

  return shipStationOrderTracking;
};

const getWarehouseOrders = async (orderInfo, databaseOrders, email) => {
  // const apiCode = "order.batchgetstatus"
  // const wareHouseOrders = orderInfo.filter((order) => warehouseProducts.includes(order["SKU"])).map((order) => {

  //   return generateUniqueOrderID(email,order["Order ID"])
  // })
  // const bizparam = {
  //   ExtorderNos: wareHouseOrders
  // }
  // const apiParam = generateWarehouseParam(apiCode, bizparam)
  // const result = await axios.post(WAREHOUSE_URL, apiParam);
  const result = {};
  const trackingNumberToUpdate = {};
  const orderWithTrackingNum = orderInfo.map((obj) => {
    const foundWarehouseOrder = result?.data?.data?.find((order) => {
      return order.extorderno === generateUniqueOrderID(email, obj["Order ID"]);
    });

    const shouldUpdateDBTracking = databaseOrders.find((order) => {
      return (
        order["Order ID"] === obj["Order ID"] &&
        !order["Tracking Number"] &&
        obj["Tracking Number"]
      );
    });
    if (shouldUpdateDBTracking) {
      trackingNumberToUpdate[obj["Order ID"]] = obj["Tracking Number"];
    }
    if (foundWarehouseOrder) {
      trackingNumberToUpdate[foundWarehouseOrder.extorderno] =
        foundWarehouseOrder.postaltrackingnumber;
      return {
        ...obj,
        ["Tracking Number"]: foundWarehouseOrder.postaltrackingnumber,
      };
    } else {
      return obj;
    }
  });

  const updates = Object.keys(trackingNumberToUpdate).map((orderID) => {
    return {
      orderID: generateUniqueOrderID(email, orderID),
      trackingNumber: trackingNumberToUpdate[orderID],
    };
  });
  const url = `${BASE_URL}/api/order/update`;

  if (updates.length) {
    const response = await axios.post(url, { updates });
  }

  return orderWithTrackingNum;
  // setOrderInfo(orderWithTrackingNum)
  // setIsInitialLoad(false)
};

export const fetchPastOrders = async (email) => {
  const url = `${BASE_URL}/api/order`;

  const isAdmin = localStorage.getItem("isAdmin") === "lingligantz@gmail.com";

  const response = await axios.post(url, { email, isAdmin });
  const convertedOrders = response.data.orders.map((order) => {
    const {
      status,
      orderID,
      productName,
      buyer,
      address,
      amount,
      sku,
      quantity,
      trackingNumber,
      date,
      phone,
      state,
      city,
      zipcode,
      shopifyID,
      shipStationID,
    } = order;
    return {
      ["Order Status"]: status,
      ["Order ID"]: revertOriginalOrderID(orderID),
      ["Product Name"]: productName,
      ["Buyer Username"]: buyer,
      ["Shipping Information"]: address,
      ["Order Amount"]: amount,
      ["SKU"]: sku,
      ["Quantity"]: quantity,
      ["Tracking Number"]: trackingNumber,
      ["Date"]: date,
      ["Phone"]: phone,
      ["State"]: state,
      ["City"]: city,
      ["Zipcode"]: zipcode,
      shopifyID,
      shipStationID,
    };
  });
  const exampleOrder = {
    ["Order Status"]: "To-ship",
    ["Order ID"]: "Example-Id",
    ["Product Name"]: "Example-product",
    ["Buyer Username"]: "Example-buyer",
    ["Shipping Information"]: "Example-address",
    ["Order Amount"]: 10.99,
    ["SKU"]: "Example-SKU",
    ["Quantity"]: "1",
    ["Tracking Number"]: "Example-trackingNumber",
    ["Date"]: "01/01/2024",
  };
  const shopifyOrders = convertedOrders.filter((order) => order.shopifyID);
  const shopifyOrderList = await getShopifyOrderInfo(shopifyOrders);
  const shipStationOrders = convertedOrders.filter(
    (order) => order.shipStationID
  );
  const shipStationOrderList = await getShipStationOrderInfo(shipStationOrders);
  const sheetOrders = convertedOrders.filter((order) => sheetMap[order.SKU]);

  const sheetOrderList = await getSheetOrderInfo(sheetOrders);

  const replacedShopifyOrders = [exampleOrder, ...convertedOrders].map(
    (order) => {
      const foundOrder = [
        ...shopifyOrderList,
        ...shipStationOrderList,
        ...sheetOrderList,
      ]?.find((combinedOrder) => {
        return combinedOrder["Order ID"] === order["Order ID"];
      });

      if (foundOrder) {
        return foundOrder;
      } else {
        return order;
      }
    }
  );
  const result = getWarehouseOrders(
    replacedShopifyOrders,
    convertedOrders,
    email
  );
  return result;
};
