import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_URL } from "../constant";

const SupplierRequest = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const url = `${BASE_URL}/api/users/requests`;
    axios
      .get(url)
      .then((res) => setRequests(res.data))
      .catch((err) => console.log(err));
  }, []);


  const updateIsVerified = async (id) => {
    try {
      // Define the endpoint URL
      const url = `${BASE_URL}/api/users/verify/${id}`;
  

      // Make the PATCH request using Axios
      const response = await axios.patch(url);
      // Check if the request was successful
      if (response.status === 200) {
        const updatedUser = response.data;
        // Handle the successful update (e.g., display a success message)
        console.log('User updated successfully:', updatedUser);
        setRequests((prev) => prev.filter((p) => p._id !== id));
        // Add additional success handling here, such as updating UI or state
      } else {
        // Handle unsuccessful response (e.g., display an error message)
        console.error('Failed to update user:', response.data);
        // Add additional error handling here
      }
    } catch (error) {
      // Handle any errors that occurred during the Axios request
      console.error('Error updating user:', error);
      // Add additional error handling here
    }
  }

  if (!requests)
    return (
      <div>
        <h2 className="text-2xl font-semibold mb-14">Supplier Request</h2>
        <div className="h-[300px] flex items-start justify-center">
          <h2 className="text-2xl font-semibold mb-14">Request is empty!</h2>
        </div>
      </div>
    );

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-14">Supplier Request</h2>

      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Requested At
              </th>
              <th scope="col" className="px-6 py-3">
                Accept
              </th>
              <th scope="col" className="px-6 py-3">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {requests.map((r) => (
              <tr
                key={r._id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {r.firstName + r.lastName}
                </th>
                <td className="px-6 py-4"> {r.email} </td>
                <td className="px-6 py-4"> {r.createdAt} </td>
                <td className="px-6 py-4">
                  <button onClick={() => updateIsVerified(r._id)} className="px-6 py-2 rounded-3xl text-white bg-green-500">
                    Accept
                  </button>
                </td>
                <td className="px-6 py-4">
                  <button className="px-6 py-2 rounded-3xl text-white bg-red-500">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupplierRequest;