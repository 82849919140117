import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { RecoilRoot } from "recoil";
import AuthProvider from "./auth/AuthProvider";
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ContextProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ContextProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);
