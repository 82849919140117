// export const BASE_URL = "https://dropshipping-deploy-5oj5.vercel.app"

// export const BASE_URL = "http://localhost:8080";

// export const BASE_URL = "https://dropshipping-deploy-5oj5.vercel.app"

export const BASE_URL = "https://droptok.onrender.com";

export const BASE_CLIENT_URL = "http://localhost:3000";

export const shopNameTokenMap = {
  ["951085-4b.myshopify.com"]: "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",
  ["playproco.com"]: "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",
};

export const skuShopMap = {
  ["42314703601804"]: "951085-4b.myshopify.com",
  ["47453168501029"]: "playproco.com",
};

export const warehouseProducts = ["49-hotairbrush"];

export const shipStationMap = {
  ["NTX-7"]:
    "6ab1dd16946f401682cd2d39faba1f1b:c1bb08176f974975b10d5e1c45bc671d",
};

export const sheetMap = {
  ["NTX-9"]: "1kjxy6rc-xmRm_od_AXoyh8SnL6MrUymepEtZIGEGEKE",
};

export const emailMap = ["NTX-9"];

export const tkAppKey = "6bocom6217onl";
export const tkAppSecret = "87ac25571ff458319458636687c01e825ff8cd8a";
