import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  Ecommerce,
  Orders,
  Products,
  Contacts,
  AddProduct,
  BulkAdmin,
} from "./pages";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Signup from "./components/Singup";
import Login from "./components/Login";

import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";
import { Checkout } from "./pages/Checkout";
import Profile from "./components/Profile";
import { AuthContext } from "./auth/AuthProvider";
import MemberProducts from "./pages/MemberProduct";

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
  // const user = localStorage.getItem("token");
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          {user && (
            <>
              {activeMenu ? (
                <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                  <Sidebar />
                </div>
              ) : (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              )}
            </>
          )}
          <div
            className={
              activeMenu && user
                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
            }
          >
            {
              <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                <Navbar />
              </div>
            }
            <div>
              <Routes>
                {/* pages  */}

                {
                  <Route
                    path="/"
                    exact
                    element={user ? <Ecommerce /> : <Products />}
                  />
                }
                {user && <Route path="/profile" exact element={<Profile />} />}
                {user && <Route path="/add" exact element={<AddProduct />} />}
                {user && <Route path="/bulk" exact element={<BulkAdmin />} />}

                {user && <Route path="/orders" exact element={<Orders />} />}
                {user && (
                  <Route path="/products" exact element={<MemberProducts />} />
                )}
                {user && (
                  <Route path="/contacts" exact element={<Contacts />} />
                )}
                {user && (
                  <Route path="/checkout" exact element={<Checkout />} />
                )}

                {user && (
                  <Route
                    path="/login"
                    exact
                    element={<Navigate replace to="/" />}
                  />
                )}
                {user && (
                  <Route
                    path="/signup"
                    exact
                    element={<Navigate replace to="/" />}
                  />
                )}

                <Route path="/login" exact element={<Login />} />
                <Route path="/signup" exact element={<Signup />} />

                <Route path="/" element={<Navigate replace to="/login" />} />
                <Route
                  path="/checkout"
                  element={<Navigate replace to="/login" />}
                />
                <Route
                  path="/profile"
                  exact
                  element={<Navigate replace to="/login" />}
                />

                <Route
                  path="/add"
                  exact
                  element={<Navigate replace to="/login" />}
                />
                <Route
                  path="/bulk"
                  exact
                  element={<Navigate replace to="/login" />}
                />
                <Route
                  path="/contacts"
                  element={<Navigate replace to="/login" />}
                />
                <Route
                  path="/orders"
                  element={<Navigate replace to="/login" />}
                />
                <Route
                  path="/products"
                  element={<Navigate replace to="/login" />}
                />
                {/* <Route path="/customers" element={<Navigate replace to="/login" />} /> */}
              </Routes>
            </div>
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
