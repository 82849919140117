import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  Sort,
  ContextMenu,
  Filter,
  Page,
  ExcelExport,
  PdfExport,
  Edit,
  Inject,
  Toolbar,
} from "@syncfusion/ej2-react-grids";

import {
  ordersData,
  contextMenuItems,
  ordersGrid,
  orderColumns,
  currencyTemplate,
} from "../data/dummy";
import { Button, Header } from "../components";
import * as XLSX from "xlsx";
import { Modal } from "antd";

import styles from "./Orders.module.css";
import { Checkout } from "./Checkout";
import axios from "axios";
import { useRecoilValue, useRecoilState } from "recoil";
import { ordersAtom, productsAtom } from "../atoms/atom";
import { productsData } from "./Ecommerce";
import { Tooltip } from "@syncfusion/ej2-react-popups";
import { getValue } from "@syncfusion/ej2-base";
import { BASE_URL } from "../constant";
import { Alert, message } from "antd";
import {
  WAREHOUSE_APIKEY,
  WAREHOUSE_CODE,
  WAREHOUSE_URL,
} from "../constantKey";
import {
  afterPayment,
  generateUniqueOrderID,
  generateWarehouseParam,
  getFormattedDate,
  revertOriginalOrderID,
} from "../util";
import { v4 as uuid } from "uuid";
import { fetchPastOrders, fetchProduct } from "../atoms/selectors";
import { AuthContext } from "../auth/AuthProvider";
import { isEmpty } from "lodash";

const Orders = () => {
  const requiredFields = [
    "Order ID",
    "Order Status",
    "Seller SKU",
    "Product Name",
    "Quantity",
    "Buyer Username",
    "Shipping Information",
    "City",
    "State",
    "Country",
    "Zipcode",
    "Phone",
  ];
  const [messageApi, contextHolder] = message.useMessage();
  const editing = { allowDeleting: false, allowEditing: false };
  // const [orderInfo, setOrderInfo] = useState([]);
  const [orderInfo, setOrderInfo] = useRecoilState(ordersAtom);
  const [productState, setProductState] = useRecoilState(productsAtom);
  const [initialLoad, setIsInitialLoad] = useState(true);
  const email = localStorage.getItem("email");
  const { user } = useContext(AuthContext);
  const isAdmin = user?.isAdmin;

  const getAliPaymentIntent = async () => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );

    if (!clientSecret) {
      return;
    }
    const paymentIntent = await axios.get(`${BASE_URL}/api/payment`, {
      params: {
        clientSecret,
      },
    });
    const newUrl = window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);

    if (paymentIntent?.data?.status === "succeeded") {
      const selectedItems = JSON.parse(paymentIntent?.data?.metadata?.items);
      const type = paymentIntent?.data?.metadata?.type;

      return { selectedItems, type };
    }
    return {};
  };

  const rowDataBound = (args) => {
    if (
      args.data["SKU"] === "Example-SKU" ||
      args.data["Order Status"] === "Paid"
    ) {
      args.isSelectable = false;

      args.row.classList.add(styles.disabledRow);
    }
  };

  // const getShopifyOrderInfo = async (orders) => {
  //   const shopifyURL = `${BASE_URL}/api/shopify/order`;

  //   const shopifyOrders = orders.filter((order) => order.shopifyID)

  //   const promises = shopifyOrders.map(async (order) => {
  //     try {
  //       const shopifyResponse = await axios.get(shopifyURL, {
  //         params: {
  //           id: order.shopifyID,
  //           sku: order.SKU,
  //         }
  //       });
  //       return shopifyResponse.data; // Assuming you want to return the data from the response
  //     } catch (error) {
  //       console.error('Error fetching data from Shopify:', error);
  //       throw error; // Re-throw the error to be caught by Promise.all
  //     }
  //   });

  //     const results = await Promise.all(promises);

  //     const shopifyOrderTracking = orders.map((order) => {
  //       const foundOrder = results.find((res) => res.id?.toString() === order.shopifyID)
  //       if(foundOrder) {
  //         return {
  //           ...order,
  //           ["Tracking Number"]: foundOrder?.fulfillments?.[0]?.["tracking_company"] && foundOrder?.fulfillments?.[0]?.["tracking_number"] ? `${foundOrder?.fulfillments?.[0]?.["tracking_company"]}:${foundOrder?.fulfillments?.[0]?.["tracking_number"]}` : null
  //         }
  //       }else{
  //         return order
  //       }
  //     })

  //     return shopifyOrderTracking

  // }

  // const getWarehouseOrders = async(orderInfo) => {

  //   const apiCode = "order.batchgetstatus"
  //   const wareHouseOrders = orderInfo.filter((order) => order["SKU"] === '49-hotairbrush').map((order) => {

  //     return generateUniqueOrderID(email,order["Order ID"])
  //   })
  //   const bizparam = {
  //     ExtorderNos: wareHouseOrders
  //   }
  //   const apiParam = generateWarehouseParam(apiCode, bizparam)
  //   const result = await axios.post(WAREHOUSE_URL, apiParam);
  //   const trackingNumberToUpdate = {}
  //   const orderWithTrackingNum = orderInfo.map((obj) => {

  //     const foundWarehouseOrder = result?.data?.data?.find((order) => {
  //       return order.extorderno === generateUniqueOrderID(email,obj["Order ID"])
  //     })
  //     if(foundWarehouseOrder){
  //       trackingNumberToUpdate[foundWarehouseOrder.extorderno] = foundWarehouseOrder.postaltrackingnumber
  //       return {
  //         ...obj,
  //         ["Tracking Number"]: foundWarehouseOrder.postaltrackingnumber

  //       }
  //     }else{
  //       return obj
  //     }

  //   })

  //   const updates =  Object.keys(trackingNumberToUpdate).map((orderID) => {
  //     return {
  //       orderID,
  //       trackingNumber: trackingNumberToUpdate[orderID]
  //     }
  //   })
  //   const url = `${BASE_URL}/api/order/update`;

  //   if(updates.length){
  //     const response = await axios.post(url, {updates});

  //   }

  //   setOrderInfo(orderWithTrackingNum)
  //   setIsInitialLoad(false)
  // }

  const error = (message) => {
    messageApi.open({
      type: "error",
      duration: 5,
      content: message ?? "Something is wrong with your excel file",
      style: {
        marginTop: "5vh",
        fontSize: "15px",
        fontWeight: "bold",
        color: "red",
      },
    });
  };

  const productPriceMap = useMemo(
    () =>
      productState.reduce((res, product) => {
        res[product.SKU?.trim()] = product.Price;
        return res;
      }, {}),
    [productState]
  );

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      const seenOrderIDs = new Set();

      orderInfo.forEach((item) => {
        const orderID = Number(item["Order ID"]);
        if (orderID) {
          seenOrderIDs.add(orderID);
        }
      });

      for (const item of parsedData) {
        const orderID = Number(item["Order ID"]);
        if (!orderID) {
          continue;
        }
        // Check if every field is not empty
        const isEmptyField = Object.keys(item)
          .filter((field) => requiredFields.includes(field))
          .some((value) => item[value] === "");
        if (isEmptyField) {
          error("All fields in the excel must be filled in.");
          return;
        }

        // Check if "Order ID" is unique
        if (seenOrderIDs.has(Number(item["Order ID"]))) {
          error("Duplicate Order ID found.");
          return;
        }
        if (!productPriceMap[item["Seller SKU"].trim()]) {
          error("Wrong SKU found! Please make sure to enter the correct sku");
          return;
        }
        // Add the "Order ID" to the set to track uniqueness
        seenOrderIDs.add(Number(item["Order ID"]));
      }

      const filteredParsedData = parsedData
        .filter((data) => {
          const orderID = Number(data["Order ID"]);
          const orderAlreadyExist = orderInfo.find((order) => {
            return Number(order["Order ID"]) === orderID;
          });
          return orderID && !orderAlreadyExist;
        })
        .map((order) => {
          const productSku = order["Seller SKU"].trim();

          const amount =
            Number(productPriceMap[productSku]) * order["Quantity"];

          return {
            ...order,
            ["Order Amount"]: amount,
            ["Order Status"]: "To ship",
            ["Shipping Information"]: order["Detail Address"],
            ["SKU"]: order["Seller SKU"],
            ["Quantity"]: Number(order["Quantity"]),
          };
        });

      const url = `${BASE_URL}/api/order/upload`;

      const orderToUpload = filteredParsedData.map((data) => {
        return {
          writer: email,
          orderID: generateUniqueOrderID(email, data["Order ID"]),
          status: data["Order Status"],
          productName: data["Product Name"],
          buyer: data["Buyer Username"],
          address: data["Shipping Information"],
          amount: data["Order Amount"],
          sku: data["Seller SKU"]?.trim(),
          quantity: data["Quantity"],
          trackingNumber: data["Tracking Number"],
          date: getFormattedDate(),
          phone: data["Phone #"],
          state: data["State"],
          city: data["City"],
          zipcode: data["Zipcode"],
        };
      });
      await axios.post(url, orderToUpload);
      // setOrderInfo((prevOrders) => [...filteredParsedData, ...prevOrders]);
      const [exampleOrder, ...rest] = orderInfo;
      setOrderInfo([exampleOrder, ...filteredParsedData, ...rest]);
    };
  };
  // const isAdmin = localStorage.getItem("isAdmin") === 'lingligantz@gmail.com';

  // const fetchPastOrders = async () => {
  //   const url = `${BASE_URL}/api/order`;

  //       const response = await axios.post(url, {email, isAdmin});
  //       const convertedOrders = response.data.orders.map((order) => {
  //         const {status, orderID, productName, buyer, address, amount, sku, quantity, trackingNumber, date,
  //         phone, state, city, zipcode, shopifyID
  //         } = order
  //         return {
  //           ["Order Status"]: status,
  //           ["Order ID"]: revertOriginalOrderID(orderID),
  //           ["Product Name"]: productName,
  //           ["Buyer Username"]: buyer,
  //           ["Shipping Information"]: address,
  //           ["Order Amount"]: amount,
  //           ["SKU"]: sku,
  //           ["Quantity"]: quantity,
  //           ["Tracking Number"]: trackingNumber,
  //           ["Date"]: date,
  //           ["Phone"]: phone,
  //           ["State"]: state,
  //           ["City"]: city,
  //           ["Zipcode"]: zipcode,
  //           shopifyID
  //         }
  //       })
  //       const exampleOrder = {
  //           ["Order Status"]: "To-ship",
  //           ["Order ID"]: "Example-Id",
  //           ["Product Name"]: "Example-product",
  //           ["Buyer Username"]: "Example-buyer",
  //           ["Shipping Information"]: "Example-address",
  //           ["Order Amount"]: 10.99,
  //           ["SKU"]: "Example-SKU",
  //           ["Quantity"]: "1",
  //           ["Tracking Number"]: "Example-trackingNumber",
  //           ["Date"]: "01/01/2024"
  //       }
  //       const shopifyOrders = convertedOrders.filter((order) => order.shopifyID)
  //       const shopifyOrderList = await getShopifyOrderInfo(shopifyOrders)
  //       const replacedShopifyOrders = [exampleOrder, ...convertedOrders].map((order) => {
  //         const foundShopifyOrder = shopifyOrderList?.find((shopifyOrder) => {
  //           return shopifyOrder["Order ID"] === order["Order ID"];
  //         })
  //         if(foundShopifyOrder){
  //           return foundShopifyOrder
  //         }else{
  //           return order
  //         }
  //       })
  //       getWarehouseOrders(replacedShopifyOrders)

  // }

  // const fetchProduct = async () => {
  //   const url = `${BASE_URL}/api/product`;

  //       const response = await axios.post(url);
  //       const products = response.data.product;
  //       const santitizedProduct = products.map((product) => {
  //         return {
  //           ...product,
  //           ["Price"]: product.price,
  //           ["Inventory"]: product.inventory,
  //           ["SKU"]: product.sku
  //         }
  //       })
  //       setProductState(santitizedProduct)
  // }

  useEffect(async () => {
    if (productState.length === 0) {
      const products = await fetchProduct();
      setProductState(products);
      const requestData = {
        products,
      };
      await axios.post(`${BASE_URL}/api/product/updateInventory`, requestData);
    }

    let orders = await fetchPastOrders(email);
    const paymentIntent = await getAliPaymentIntent();
    if (!isEmpty(paymentIntent)) {
      await afterPayment(
        paymentIntent?.selectedItems,
        paymentIntent?.type,
        orderInfo,
        setProductState,
        setOrderInfo,
        email,
        productState
      );
      orders = await fetchPastOrders(email);
    }
    setOrderInfo(orders);
  }, [email]);

  // useEffect(() => {

  //   if(orderInfo.length && initialLoad){
  //     getWarehouseOrders()
  //   }
  // }, [orderInfo])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [outStockItems, setOutStockItems] = useState([]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    const rowSelected = grid.getSelectedRecords();

    const orderIDs = rowSelected.map((row) => {
      return `${email}:${row["Order ID"]}`;
    });
    await axios.post(`${BASE_URL}/api/order/delete`, {
      orderIDs,
      isAdmin,
    });
  };

  let grid;
  const handleCheckout = () => {
    const rowSelected = grid.getSelectedRecords();

    const sanitizedItems = rowSelected.map((row) => {
      return {
        id: row["Order ID"],
        amount: row["Order Amount"],
        quantity: row["Quantity"],
        sku: row["Seller SKU"] || row["SKU"],
        email,
        name: row["Buyer Username"],
        address: row["Shipping Information"],
        phone: row["Phone"],
        city: row["City"],
        province: row["State"],
        zipcode: row["Zipcode"],
      };
    });
    const outStocks = [];
    sanitizedItems.forEach((row) => {
      const foundProduct = productState.find((product) => {
        return product?.SKU === row.sku?.trim();
      });
      if (Number(foundProduct.Inventory) < Number(row.quantity)) {
        outStocks.push(foundProduct.productName);
      }
    });
    if (outStocks.length) {
      setOutStockItems(outStocks);
    } else {
      setOutStockItems([]);
    }
    setSelectedItems(sanitizedItems);
    setIsModalOpen(true);
  };

  const toolbar = ["ExcelExport"];
  const toolbarClick = (args) => {
    if (grid && args.item.id === "gridcomp_excelexport") {
      grid.excelExport();
    }
  };

  const handleTemplateDownload = () => {
    // Construct the URL for the template file in the public folder
    const templateUrl = process.env.PUBLIC_URL + "/template.xlsx";

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = templateUrl;
    link.download = "template.xlsx";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  //   const tooltip = (args) => {
  //     console.log('aaaa', args)

  //     const tooltip = new Tooltip({
  //         content: getValue(args.column.field, args.data).toString()
  //     });
  //     tooltip.appendTo(args.cell);
  // };

  // const sortedOrderInfo = useMemo(() => {
  // //  return orderInfo?.sort((a, b) => a.orderID - b.orderID)
  // return orderInfo?.sort()

  // })
  // console.log('ooo', orderInfo, sortedOrderInfo)

  // const dataBound = () => {
  //   (grid).hideScroll();
  // }

  const bodyText = useMemo(() => {
    return (
      <div>
        <strong>Instructions:</strong>&nbsp;Please upload this excel template or
        use the TikTok official one to place orders.
        <p>
          <strong>After Payment:</strong>&nbsp;Tracking numbers will be updated
          in two business days
        </p>
      </div>
    );
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {contextHolder}
      <Header category="Page" title="Orders" body={bodyText} />
      {/* <div>Please download excel template and upload excel to place the order</div> */}
      <div className={styles.buttonContainer}>
        <Button
          text="Download Template"
          borderRadius="10px"
          className={styles.templateButton}
          onClick={handleTemplateDownload}
        />
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          id="excelFileInput"
          className={styles.excelFileInput}
        />
        <label for="excelFileInput" className={styles.uploadButton}>
          Upload Excel
        </label>

        <Button
          text="Pay"
          borderRadius="10px"
          className={styles.paymentButton}
          onClick={handleCheckout}
        />
        {isAdmin && (
          <Button
            text="Delete"
            borderRadius="10px"
            className={styles.deleteButton}
            onClick={handleDelete}
          />
        )}
      </div>

      <Modal
        title="Payment"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedItems.length === 0 ? (
          <div>Please Select At Least One Order To Proceed to Payment</div>
        ) : outStockItems.length > 0 ? (
          <div>{outStockItems.join(", ")} does not have enough stock</div>
        ) : (
          <Checkout
            selectedItems={selectedItems}
            setOrderInfo={setOrderInfo}
            handleCancel={handleCancel}
            orderInfo={orderInfo}
          />
        )}
      </Modal>
      <GridComponent
        ref={(g) => (grid = g)}
        id="gridcomp"
        // dataSource={ordersData}
        // dataSource={sortedOrderInfo}
        dataSource={orderInfo}
        // queryCellInfo={tooltip}
        allowPaging
        allowSorting
        allowExcelExport={true}
        toolbarClick={toolbarClick}
        toolbar={toolbar}
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
        width={1000}
        rowDataBound={rowDataBound}
      >
        <ColumnsDirective>
          {orderColumns.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Toolbar,
            Resize,
            Sort,
            ContextMenu,
            Filter,
            Page,
            ExcelExport,
            Edit,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};
export default Orders;
