import React, { useState } from 'react';
import styles from './Waitlist.module.css'; // Import the module CSS file for styling
import axios from 'axios'; // Import axios for making HTTP requests
import {BASE_URL } from "../constant";

const Waitlist = (props) => {
    const {setShowWaitlist} = props
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // Add state to track message type (success or error)

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!email){
            setMessage('Please enter your email!');
            setMessageType('error'); // Set message type to 'success'
            return
        }
        try {
            // Send a POST request to the backend API to save the email
            const response = await axios.post(`${BASE_URL}/api/waitlist`, { email });

            // Check if the request was successful
            if (response.data.success) {
                // Set a success message
                // setMessage('You have successfully joined the waitlist!');
                // setMessageType('success'); // Set message type to 'success'
                setShowWaitlist(false)
            }
        } catch (error) {
            // If an error occurs, log it to the console
            console.error('Error joining waitlist:', error);
            // Set an error message
            setMessage('Failed to join the waitlist. Please try again later.');
            setMessageType('error'); // Set message type to 'error'
        }

        // Reset the email input field after submission
        setEmail('');
    };

    return (
        <div className={styles.waitlistContainer}>
            <h1 className={styles.waitlistTitle}>Join the DropTK Waitlist</h1>
            <p className={styles.waitlistDescription}>A dropshipping platform for TikTok shops</p>

            <form className={styles.waitlistForm} onSubmit={handleSubmit}>
                <input
                    type="email"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={handleEmailChange}
                    className={styles.emailInput}
                />
                {message && (
                    <p className={messageType === 'success' ? styles.successMessage : styles.errorMessage}>
                        {message}
                    </p>
                )}
                <button type="submit" className={styles.submitButton}>Join Waitlist</button>
            </form>
        </div>
    );
};

export default Waitlist;