import React from 'react';

import { useStateContext } from '../contexts/ContextProvider';

const Button = ({ icon, bgColor, color, bgHoverColor, size, text, borderRadius, width, onClick, className, ...props }) => {
  const { setIsClicked, initialState } = useStateContext();
  const {disabled} = props
  return (
    <button
    disabled={disabled}
      type="button"
      onClick={onClick ?? (() => setIsClicked(initialState))}
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={className ?? ` text-${size} p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      {icon} {text}
    </button>
  );
};

export default Button;
