import { jwtDecode } from "jwt-decode";
import React, { createContext } from "react";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const token = localStorage.getItem("token");
  if (!token) return <> {children} </>;

  const user = jwtDecode(token);
  if (user)
    return (
      <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;