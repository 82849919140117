import { joiResolver } from "@hookform/resolvers/joi";
import axios from "axios";
import Joi from "joi";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import { BASE_URL } from "../constant";

const schema = Joi.object({
  accontNumber: Joi.number().required(),
  routingNumber: Joi.number().required(),
  currency: Joi.string().required(),
  percentage: Joi.number().required(),
  accountType: Joi.string().required(),
});

const AddPayer = () => {
  const [enableEdit, setEnableEdit] = useState(false);
  const [payers, setPayers] = useState([]);
  const url = `${BASE_URL}/api/payers`;

  const handleDelete = (payerId) => {
    axios.delete(`${BASE_URL}/api/payers/${payerId}`).then(() => {
      setPayers(payers.filter((p) => p._id !== payerId));
    });
  };

  useEffect(() => {
    axios.get(url).then((res) => setPayers(res.data));
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schema) });
  return (
    <div className="my-6 px-5 py-14 rounded-3xl shadow-xl border">
      <div className="w-full flex items-center justify-between">
        <h2 className="text-xl font-bold">Manage payment</h2>
        <button
          onClick={() => setEnableEdit(true)}
          className="p-2 px-6 bg-blue-500 text-white rounded-3xl"
        >
          Add New
        </button>
      </div>

      <div className="my-8">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Account No.
              </th>
              <th scope="col" className="px-6 py-3">
                Routing No.
              </th>
              <th scope="col" className="px-6 py-3">
                Currency
              </th>
              <th scope="col" className="px-6 py-3">
                Account Type
              </th>
              <th scope="col" className="px-6 py-3">
                Percentage
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {payers.map((r) => (
              <tr
                key={r._id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {r.accontNumber}
                </th>
                <td className="px-6 py-4"> {r.routingNumber} </td>
                <td className="px-6 py-4"> {r.currency} </td>
                <td className="px-6 py-4"> {r.accountType} </td>
                <td className="px-6 py-4"> {r.percentage} </td>
                <td className="px-6 py-4">
                  <button className="px-6 py-2 rounded-3xl text-white bg-yellow-500">
                    Edit
                  </button>
                </td>
                <td className="px-6 py-4">
                  <button
                    className="px-6 py-2 rounded-3xl text-white bg-red-500"
                    onClick={() => handleDelete(r._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {enableEdit && (
        <div
          className="w-full h-screen fixed top-0 right-0 flex items-center justify-center z-[100000000]"
          style={{ background: "rgba(0,0,0,.5)" }}
        >
          <div className="p-8 bg-white relative rounded-3xl">
            <IoClose
              onClick={() => setEnableEdit(!enableEdit)}
              className="text-2xl absolute top-3 right-3 cursor-pointer"
            />
            <h1 className="text-center font-semibold text-2xl mb-8">
              Add Payer
            </h1>
            <form
              onSubmit={handleSubmit((data) =>
                axios.post(url, data).then((res) => {
                  setEnableEdit(false);
                  window.location.reload();
                })
              )}
              className="flex flex-col gap-4 w-full"
            >
              <div>
                <input
                  {...register("accontNumber")}
                  className="border w-full p-3 outline-none"
                  type="number"
                  placeholder="Account Number"
                />
                {errors.accontNumber && (
                  <p className="text-red-500">{errors.accontNumber.message}</p>
                )}
              </div>
              <div>
                <input
                  {...register("routingNumber")}
                  className="border w-full p-3 outline-none"
                  type="number"
                  placeholder="Routing Number"
                />
                {errors.routingNumber && (
                  <p className="text-red-500">
                    {" "}
                    {errors.routingNumber.message}{" "}
                  </p>
                )}
              </div>
              <div>
                <input
                  {...register("currency")}
                  className="border w-full p-3 outline-none"
                  type="text"
                  placeholder="Currency"
                />
                {errors.currency && (
                  <p className="text-red-500"> {errors.currency.message} </p>
                )}
              </div>
              <div>
                <input
                  {...register("percentage")}
                  className="border w-full p-3 outline-none"
                  type="number"
                  placeholder="Percentage"
                />
                {errors.percentage && (
                  <p className="text-red-500"> {errors.percentage.message} </p>
                )}
              </div>
              <div>
                <input
                  {...register("accountType")}
                  className="border w-full p-3 outline-none"
                  type="text"
                  placeholder="Account Type"
                />
                {errors.accountType && (
                  <p className="text-red-500"> {errors.accountType.message} </p>
                )}
              </div>

              <button className="p-3 w-full bg-[#3bb19b] text-white">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddPayer;
