import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { productsAtom } from "../atoms/atom";
import { useRecoilState } from "recoil";
import {
  BASE_URL,
  emailMap,
  shipStationMap,
  skuShopMap,
  warehouseProducts,
} from "../constant";
import { WAREHOUSE_CODE, WAREHOUSE_URL } from "../constantKey";
import {
  generateUniqueOrderID,
  generateWarehouseParam,
  getFormattedDate,
  orderToWarehouseConverter,
} from "../util";

export default function CheckoutForm(props) {
  const {
    selectedItems,
    setOrderInfo,
    handleCancel,
    orderInfo,
    setClientSecret,
    type,
  } = props;

  const totalAmount = selectedItems.reduce((sum, row) => {
    const result = sum + Number(row.amount);
    return result;
  }, 0);
  const [productState, setProductState] = useRecoilState(productsAtom);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const updateOrderInfo = () => {
  //   setOrderInfo((prevOrders) => {
  //     return prevOrders.map((order) => {
  //       if(order["Order ID"] === selectedItems["Order ID"]){
  //         return {
  //           ...order,
  //           ["Order Status"]: "Paid"
  //         }
  //       }else{
  //         return order
  //       }
  //     })
  //   })
  // }
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const email = localStorage.getItem("email");

  const updateProduct = async (productToUpdate) => {
    const url = `${BASE_URL}/api/product/update`;

    await axios.post(url, { product: productToUpdate });
  };

  // const generateOrder = async(orders) => {
  //   console.log('oooo', orders);
  //   const apiCode = "order.addbatchv2"

  //   const bizparam = {
  //     Data:
  //     [{shippingaddress:"test",
  //     warehousecode:WAREHOUSE_CODE,
  //     logisticsservice:"USPS-GA&PM",
  //     extorderno:"TEST202401231422",
  //     orderdate:"2024-03-06T07",
  //     addresseename:"nametest",
  //     address:"addresstest",
  //     country:"US",
  //     province:"NY",city:"Holtsville",postalcode:"00501",phone:"13600000000",orderlength:0,orderwidth:0,orderheight:0,orderweight:0,products:[{sku:"49-hotairbrush",skuqty:1,skuprice:10,cnname:"中文名称",enname:"English",sizel:1,sizew:1,sizeh:1,weightkg:0.1 }]}]
  //   }
  //   const apiParam = generateWarehouseParam(apiCode, bizparam)
  //   const result = await axios.post(WAREHOUSE_URL, apiParam);
  //   console.log('rrr', result)
  // }

  const generateOrder = async (orders) => {
    const none = orders.every(
      (order) => !warehouseProducts.includes(order["SKU"])
    );
    if (none) {
      return;
    }
    const currentDate = new Date();
    const warehouseOrderDate = currentDate.toISOString().slice(0, 19);
    const dataArr = orders
      .filter((order) => warehouseProducts.includes(order["SKU"]?.trim()))
      .map((order) => {
        return {
          shippingaddress: "",
          warehousecode: WAREHOUSE_CODE,
          logisticsservice: "USPS-GA&PM",
          extorderno: generateUniqueOrderID(
            email,
            order["Order ID"]?.toString()
          ),
          orderdate: warehouseOrderDate,
          addresseename: order["Buyer Username"],

          address: order["Shipping Information"],
          country: "United States",
          province: order["State"],
          city: order["City"],
          postalcode: order["Zipcode"]?.toString(),
          phone: order["Phone"],
          country: "US",
          // province:"NY",city:"New York",
          // postalcode:"32132",
          // phone:"121321",

          orderlength: 0,
          orderwidth: 0,
          orderheight: 0,
          orderweight: 0,
          products: [
            {
              sku: order["SKU"]?.trim(),
              skuqty: order["Quantity"],
              skuprice: order["Order Amount"],
              cnname: "中文名称",
              enname: order["Product Name"],
              sizel: 1,
              sizew: 1,
              sizeh: 1,
              weightkg: 0.1,
            },
          ],
        };
      });
    const apiCode = "order.addbatchv2";

    const bizparam = {
      Data: dataArr,
    };
    const apiParam = generateWarehouseParam(apiCode, bizparam);
    const result = await axios.post(WAREHOUSE_URL, apiParam);
  };

  const postShipStationOrder = async (orders) => {
    const promises = orders
      .filter((order) => shipStationMap[order.sku])
      .map(async (item) => {
        const shipStationURL = `${BASE_URL}/api/shipstation/order/create`;
        const orderDate = new Date().toISOString();
        const order = {
          orderNumber: generateUniqueOrderID(email, item.id),
          orderDate,
          orderStatus: "awaiting_shipment",
          items: [
            {
              sku: item.sku,
              name: item.productName,
              quantity: item.quantity,
              unitPrice: item.amount,
            },
          ],
          internalNotes: "DropTK",
          shipTo: {
            name: item.name,
            street1: item.address,
            city: item.city,
            state: item.state,
            postalCode: item.zipcode,
            country: "US",
            phone: item.phone,
          },
          billTo: {
            name: item.name,
            street1: item.address,
            city: item.city,
            state: item.state,
            postalCode: item.zipcode,
            country: "US",
            phone: item.phone,
          },
        };
        try {
          const shipstationRes = await axios.post(shipStationURL, order);
          return shipstationRes.data; // Assuming you want to return the data from the response
        } catch (error) {
          console.error("Error posting order to Shipstation:", error);
          throw error; // Re-throw the error to be caught by Promise.all
        }
      });

    try {
      const results = await Promise.all(promises);
      const orders = selectedItems.map((item) => item.id);
      postShipStationOrderUpdate(orders, results);
      return results;
    } catch (error) {
      console.error("Error posting orders to Shipstation:", error);
      throw error;
    }
  };
  const postShopifyOrder = async (orders) => {
    const promises = orders
      .filter(
        (order) =>
          skuShopMap[order.sku] ||
          (order?.platform === "Shopify" && order?.account)
      )
      .map(async (item) => {
        const shopifyURL = `${BASE_URL}/api/shopify/order/create`;
        const order = {
          id: item.sku,
          quantity: item.quantity,
          email,
          name: item.name,
          address: item.address,
          phone: item.phone,
          city: item.city,
          province: item.state,
          zipcode: item.zipcode,
          note: generateUniqueOrderID(email, item.id),
        };
        try {
          const shopifyRes = await axios.post(shopifyURL, order);
          return shopifyRes.data; // Assuming you want to return the data from the response
        } catch (error) {
          console.error("Error posting order to Shopify:", error);
          throw error; // Re-throw the error to be caught by Promise.all
        }
      });

    try {
      const results = await Promise.all(promises);
      const orders = selectedItems.map((item) =>
        generateUniqueOrderID(email, item.id)
      );
      postOrderUpdate(orders, results);
      return results;
    } catch (error) {
      console.error("Error posting orders to Shopify:", error);
      throw error;
    }
  };
  const postOrderUpdate = async (orders, shopifyResponses) => {
    const url = `${BASE_URL}/api/order/update`;

    const updates = orders.map((order) => {
      const shopifyOrder = shopifyResponses.find((res) => {
        return res.note === order;
      });
      return {
        // orderID: order,
        status: "Paid",
        shopifyID: shopifyOrder?.id,
      };
    });

    const response = await axios.post(url, { updates });
  };

  const postShipStationOrderUpdate = async (orders, shipStationResponses) => {
    const url = `${BASE_URL}/api/order/update`;

    const updates = orders.map((order) => {
      const shipStationOrder = shipStationResponses.find((res) => {
        return res.orderNumber === order;
      });
      return {
        // orderID: order,
        status: "Paid",
        shipStationID: shipStationOrder?.orderNumber,
      };
    });

    const response = await axios.post(url, { updates });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: "http://localhost:3000",
        return_url: "http://localhost:3000/orders",
      },
      redirect: "if_required",
    });

    if (!error) {
      handleCancel();
    }
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error || paymentIntent?.status !== "succeeded") {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } else {
      const originalOrderID = selectedItems.map((item) => item.id);
      const paidOrders = orderInfo?.filter((order) =>
        originalOrderID.includes(order["Order ID"])
      );
      if (type === "manual") {
        const item = selectedItems[0];
        const uniqueID = generateUniqueOrderID(email, item.id);
        let shopifyRes;
        if (
          skuShopMap[item.sku] ||
          (item?.platform === "Shopify" && item?.account)
        ) {
          const shopifyURL = `${BASE_URL}/api/shopify/order/create`;
          const order = {
            id: item.sku,
            quantity: item.quantity,
            email,
            name: item.name,
            address: item.address,
            phone: item.phone,
            city: item.city,
            province: item.state,
            zipcode: item.zipcode,
            note: uniqueID,
          };

          shopifyRes = await axios.post(shopifyURL, order);
        }
        let shipStationRes;

        if (shipStationMap[item.sku]) {
          const shipStationURL = `${BASE_URL}/api/shipstation/order/create`;
          const orderDate = new Date().toISOString();
          const order = {
            orderNumber: item.id,
            orderDate,
            orderStatus: "awaiting_shipment",
            items: [
              {
                sku: item.sku,
                name: item.productName,
                quantity: item.quantity,
                unitPrice: item.amount,
              },
            ],
            internalNotes: "DropTK",
            shipTo: {
              name: item.name,
              street1: item.address,
              city: item.city,
              state: item.state,
              postalCode: item.zipcode,
              country: "US",
              phone: item.phone,
            },
            billTo: {
              name: item.name,
              street1: item.address,
              city: item.city,
              state: item.state,
              postalCode: item.zipcode,
              country: "US",
              phone: item.phone,
            },
          };
          shipStationRes = await axios.post(shipStationURL, order);
        }
        if (
          emailMap.includes(item.sku) ||
          BASE_URL === "https://droptok.onrender.com"
        ) {
          const emailURL = `${BASE_URL}/api/email/send`;
          const text = `
        Order Number: ${email}:${item.id}
    SKU: ${item.sku}
    Quantity: ${item.quantity}
    Name: ${item.name}
    Address: ${item.address}
    Phone: ${item.phone}
    City: ${item.city}
    State: ${item.state}
    Zipcode: ${item.zipcode}
`;
          const email = {
            subject: "DropTK Order",
            text,
          };
          await axios.post(emailURL, email);
        }
        const url = `${BASE_URL}/api/order/upload`;

        const orderToUpload = selectedItems.map((item) => {
          return {
            writer: email,
            orderID: uniqueID,
            status: "Paid",
            productName: item.productName,
            buyer: item.name,
            address: item.address,
            amount: item.amount,
            sku: item.sku,
            quantity: item.quantity,
            trackingNumber: null,
            date: getFormattedDate(),
            phone: item.phone,
            state: item.state,
            city: item.city,
            zipcode: item.zipcode,
            shopifyID: shopifyRes?.data?.id,
            shipStationID: shipStationRes?.data?.orderNumber,
          };
        });

        await axios.post(url, orderToUpload);
      } else {
        setOrderInfo &&
          setOrderInfo((prevOrders) => {
            const result = prevOrders.map((order) => {
              const foundItem = selectedItems.find((item) => {
                return item.id === order["Order ID"];
              });
              if (foundItem) {
                return {
                  ...order,
                  ["Order Status"]: "Paid",
                };
              } else {
                return order;
              }
            });

            return result;
          });

        postShopifyOrder(selectedItems);
        postShipStationOrder(selectedItems);
        selectedItems.forEach(async (item) => {
          if (
            emailMap.includes(item.sku) ||
            BASE_URL === "https://droptok.onrender.com"
          ) {
            const emailURL = `${BASE_URL}/api/email/send`;
            const text = `
    Order Number: ${email}:${item.id}
    SKU: ${item.sku}
    Quantity: ${item.quantity}
    Name: ${item.name}
    Address: ${item.address}
    Phone: ${item.phone}
    City: ${item.city}
    State: ${item.state}
    Zipcode: ${item.zipcode}
`;
            const email = {
              subject: "DropTK Order",
              text,
            };
            await axios.post(emailURL, email);
          }
        });
      }

      generateOrder(
        type === "manual"
          ? orderToWarehouseConverter(selectedItems)
          : paidOrders
      );
      setClientSecret("");

      const paidSKU = selectedItems.reduce((res, item) => {
        if (res[item.sku]) {
          res[item.sku] = res[item.sku] + Number(item.quantity);
        } else {
          res[item.sku] = Number(item.quantity);
        }
        return res;
      }, {});

      const originalSkuData = productState.reduce((res, item) => {
        res[item["SKU"]] = item;
        return res;
      }, {});
      const updatedProductInventory = productState.map((product) => {
        if (paidSKU[product.SKU]) {
          return {
            ...product,
            ["Inventory"]: product.Inventory - paidSKU[product.SKU],
          };
        } else {
          return product;
        }
      });
      const dbProductUpdate = [];
      for (let sku in paidSKU) {
        dbProductUpdate.push({ sku, inventory: paidSKU[sku] });
      }

      updateProduct(dbProductUpdate);
      setProductState(updatedProductInventory);

      setMessage(null);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div style={{ fontWeight: "bold", textAlign: "center", padding: "5px" }}>
        Amount Due: ${totalAmount}
      </div>
      <button disabled={isLoading || !stripe || !elements} id="stripeSubmit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
