import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { TbCameraPlus } from "react-icons/tb";
import { AuthContext } from "../auth/AuthProvider";
import { BASE_URL } from "../constant";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import person from '../data/person.png'

const schema = Joi.object({
  password: Joi.string().min(8).required(),
});

const UserInfo = () => {
  const { user } = useContext(AuthContext);
  const [currentUser, setCurrentUser] = useState({});
  const [enableEdit, setEnableEdit] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: joiResolver(schema) });

  const url = `${BASE_URL}/api/users/${user._id}`;
  useState(() => {
    axios
      .get(url)
      .then((res) => setCurrentUser(res.data))
      .catch((err) => console.log(err));
  }, [user._id]);

  return (
    <>
      {enableEdit && (
        <div
          className="w-full h-screen fixed top-0 right-0 flex items-center justify-center z-[100000000]"
          style={{ background: "rgba(0,0,0,.5)" }}
        >
          <div className="p-8 bg-white relative rounded-3xl">
            <IoClose
              onClick={() => setEnableEdit(!enableEdit)}
              className="text-2xl absolute top-3 right-3 cursor-pointer"
            />
            <h1 className="text-center font-semibold text-2xl">
              Change Your Password
            </h1>
            <form
              onSubmit={handleSubmit((data) => {
                axios.patch(url, { password: data.password }).then(() => {
                  localStorage.removeItem("token");
                  window.location.href = "/login";
                });
              })}
              className="flex flex-col gap-4"
            >
              <input
                {...register("password")}
                className="border p-3 outline-none"
                type="password"
                placeholder="Enter new password"
              />
              {errors.password && (
                <p className="text-red-500"> {errors.password.message} </p>
              )}

              <button className="p-3 w-full bg-[#3bb19b] text-white">
                Change
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="flex items-center justify-center shadow-xl border rounded-3xl p-14 relative">
        <div className="space-y-3">
          <div className="relative w-40 h-40 ">
            <img className="rounded-full" src={person} alt="blank" />
            {/* <TbCameraPlus className="w-8 h-8 absolute bottom-4 right-3 cursor-pointer" /> */}
          </div>
          <div>
            <h1 className="text-3xl font-semibold uppercase">
              {currentUser.firstName + " " + currentUser.lastName}
            </h1>
            <p> {currentUser.email} </p>
          </div>
        </div>
        <button
          onClick={() => setEnableEdit(!enableEdit)}
          className="absolute top-6 right-6 text-2xl"
        >
          <FiEdit />
        </button>
      </div>
    </>
  );
};

export default UserInfo;