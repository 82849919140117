import React from 'react';
import slackQR from '../images/slackqr.jpg'
import discordQR from '../images/discord.jpg'
import styles from './Header.module.css';

const Header = ({ category, title, body }) => (
  <div className={styles.container}>
    {/* Left side: Header and Body */}
    <div className="flex flex-col mr-10">
      <div className="mb-10">
        <p className="text-lg text-gray-400">{category}</p>
        <p className="text-3xl font-extrabold tracking-tight text-slate-900">
          {title}
        </p>
      </div>
      {body && body}
    </div>

    {/* Right side: QR codes */}
    <div className={styles.qrContainer}>
      <div className={styles.qrItem}>
        <img className={styles.qrCode} src={slackQR} alt="Slack Group" />
        <a className={styles.navBeforeLoginSlack} href="https://join.slack.com/t/tiktokdropshipping/shared_invite/zt-2fr0bynm8-ZELoS1E2nQdH2C4QFu0TeA" target="_blank">Slack Group</a>
      </div>
      <div className={styles.qrItem}>
        <img className={styles.qrCode} src={discordQR} alt="Discord Group" />
        <a className={styles.navBeforeLoginDiscord} href="https://discord.gg/97t3ymhD5Q" target="_blank">Discord Group</a>
      </div>
    </div>
  </div>
);

export default Header;