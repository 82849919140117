import React, { useEffect, useState } from 'react';
import {
    Button,
    Cascader,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Mentions,
    Select,
    TreeSelect,
    Modal
  } from 'antd';
  import styles from './General.module.css';
  import {v4 as uuid} from 'uuid';

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
   
  };

  
const ManualPayment = (props) => {
    const {setShowPayment, selectedProduct, setCheckoutInfo, setShowMessage} = props
    const [form] = Form.useForm();
    const email = localStorage.getItem("email");

  const onFinish = (input) => {

// writer
// orderID
// "lingligantz@gmail.com:52145"
// status
// productName
// "4 in 1 Hair Dryer Brush & Volumizer, Hot Air Brush, One Step Blow Drye…"
// buyer
// "looollll"
// address
// "10021 Los Ranchitos Rd"
// amount
// "15.99"
// sku
// "49-hotairbrush"
// quantity
// "1"
// date
// "03/08/2024"
// phone
// "(+1)6198229333"
// state
// "California"
// city
// "Lakeside"
// zipcode
// "92040"

const {quantity, address, city, name, phone, state, zipcode} = input
    const checkoutInfo = {
        id: uuid(),
        amount: selectedProduct["Price"] * quantity,
        sku:selectedProduct["SKU"],
        quantity,
        address,
        city,
name,
phone, 
 state, 
zipcode,
productName: selectedProduct["productName"]
    }
    const isOutStock = Number(selectedProduct.Inventory) < quantity
    if(isOutStock){
        setShowMessage(true)
    }else{
        setShowMessage(false)

    }
    setCheckoutInfo(checkoutInfo)
    setShowPayment(true)
  };

    return <Form {...formItemLayout} variant="filled" style={{ width: 400, padding:50 }}
    onFinish={onFinish}
    form={form}
    >
    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter customer name' }]}>
      <Input />
    </Form.Item>


    <Form.Item label="Address" name="address" rules={[{ required: true, message: 'Please enter customer address' }]}>
    <Input />
    </Form.Item>

    <Form.Item label="City" name="city" rules={[{ required: true }]}>
    <Input />
    </Form.Item>
    
    <Form.Item label="State" name="state" rules={[{ required: true }]}>
    <Input />
    </Form.Item>
    <Form.Item label="Zipcode" name="zipcode" rules={[{ required: true }]}>
    <Input />
    </Form.Item>

    <Form.Item label="Phone" name="phone" rules={[{ required: true }]}>
    <Input />
    </Form.Item>
    <Form.Item
      label="Quantity"
      name="quantity"
      rules={[{ required: true, message: 'Please enter the quantity' }]}
    >
      <InputNumber style={{ width: '100%' }} />
</Form.Item>
<Form.Item>
      <Button key="submit" htmlType="submit" className={styles.continueButton}>
            Continue
          </Button>
    </Form.Item>


   

    {/* <Form.Item label="Select" name="Select" rules={[{ required: true, message: 'Please input!' }]}>
      <Select />
    </Form.Item> */}


  </Form>
  
  };
  export default ManualPayment;